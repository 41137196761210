import React, { useEffect, useState } from 'react';
import classes from './ForgotPassword.module.css';
import { Box, Grow, Modal, TextField } from '@mui/material';
import styled from 'styled-components';
import { FiX } from 'react-icons/fi';
import UpdatePasswordModal from './UpdatePassword/UpdatePassword';
import { useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import API from '../../../Axios/axios';
import { apiErrorCodes } from '../../../apiErrorCodes';

const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

export default function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [counter, setCounter] = useState(0);
  const [code, setCode] = useState('');
  const [enableEmailInput, setEnableEmailInput] = useState(false);
  const [enableOTPInput, setEnableOTPInput] = useState(true);
  const [disableSendOTPButton, setDisableSendOTPButton] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [errorOTPInput, setErrorOTPInput] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    setEmail(data.email);
    setDisableSendOTPButton(true);

    const formData = new FormData();
    formData.append('email', email);
    formData.append('otp_type', 'reset__password');
    // formData.append('password', data.password);
    try {
      const response = await API.post('/accounts/otp_send/', formData);
      if (response.data.code === apiErrorCodes.SUCCESS) {
        handleSendOTP();
      }
    } catch (error) {
      const errorCode = error.response.data.code
      if (
        errorCode === apiErrorCodes.EMAIL_NOT_FOUND ||
        errorCode === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        setDisableSendOTPButton(false);
        setEmailError(error.response.data.code);
      }
      else if ( errorCode === apiErrorCodes.SERIALIZATION_FAILED && error.response.data.slt )
      {
        setDisableSendOTPButton(false);
        setEmailError(`SLT_${error.response.data.slt}`);
      }
    }
  };
  const handleOtpVerify = async () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('otp', code);
    try {
      const response = await API.post('/accounts/otp_verify/', formData);
      if (response.data.success) {
        setUpdatePasswordModal(true);
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.OTP_MISMATCHED ||
        error.response.data.code === apiErrorCodes.EMAIL_NOT_FOUND ||
        error.response.data.code === apiErrorCodes.EMAIL_OTP_REQUIRED ||
        error.response.data.code === apiErrorCodes.OTP_REQUIRED
      ) {
        setErrorOTPInput(true);
      }
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      handleOtpVerify(code);
    }
  }, [code]);

  const handleSendOTP = () => {
    setCounter(60);
    setEnableOTPInput(false);
    setEmailError(false);
  };

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
      setEnableEmailInput(true);
      setDisableSendOTPButton(true);
    } else {
      setEnableEmailInput(false);
      setDisableSendOTPButton(false);
    }
  }, [counter]);

  return (
    <div>
      {updatePasswordModal && (
        <UpdatePasswordModal
          setOpenModal={props.setOpenModal}
          setUpdatePasswordModal={setUpdatePasswordModal}
          email={email}
        />
      )}
      {updatePasswordModal ? (
        ''
      ) : (
        <Modal open={true}>
          <div>
            <Grow in={true}>
              <Box className={classes.forgetPasswordModal}>
                <FiX
                  className="cross-button"
                  onClick={() => props.setOpenModal(false)}
                />
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                  className={classes.formForgetPassword}
                >
                  <Box className={classes.forgetPasswordModalHeader}>
                    <StyledTextField
                      className={classes.inputField}
                      disabled={enableEmailInput}
                      label="Registered email address"
                      variant="outlined"
                      type="text"
                      sx={{ width: '90%' }}
                      InputLabelProps={{
                        style: { fontSize: '1.4rem', color: '#6e6e6e' },
                      }}
                      InputProps={{
                        style: {
                          fontSize: 14,
                          fontWeight: 400,
                          paddingRight: 0,
                        },
                        endAdornment: disableSendOTPButton ? (
                          <button
                            className={classes.sendOTPButtonDisabled}
                            type="button"
                          >
                            Send OTP
                          </button>
                        ) : (
                          <button
                            className={classes.sendOTPButtonEnabled}
                            type="submit"
                          >
                            Send OTP
                          </button>
                        ),
                      }}
                      {...register('email', {
                        required: 'Required field',
                        onChange: () => {
                          setEmail(getValues('email'));
                          setEmailError(false);
                        },
                      })}
                      error={!!errors?.email}
                      helperText={
                        errors?.email
                          ? errors.email.message
                          : !emailError 
                          ? null
                          : emailError?.startsWith('SLT')
                          ? apiErrorCodes[`ACCOUNT_TYPE_${emailError}`]
                          : 'Invalid email address.'
                      }
                    />
                    {counter > 0 ? (
                      <h4 className={classes.resendOTPText}>
                        {' '}
                        Resend OTP in {counter} sec.
                      </h4>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box className={classes.forgetPasswordModalBody}>
                    <h4>Enter OTP</h4>
                    <Box className={classes.forgetPasswordModalOTPContainer}>
                      <OtpInput
                        isInputNum
                        isDisabled={enableOTPInput}
                        disabledStyle={{ cursor: 'not-allowed' }}
                        inputStyle={{
                          margin: '1rem',
                          height: '2.5rem',
                          width: '2.5rem',
                          border: '1px solid #6e6e6e',
                          borderRadius: ' 0.2rem',
                        }}
                        hasErrored={errorOTPInput}
                        errorStyle={{
                          margin: '1rem',
                          height: '2.5rem',
                          width: '2.5rem',
                          border: '1px solid red',
                          borderRadius: ' 0.2rem',
                        }}
                        numInputs={6}
                        value={code}
                        onChange={setCode}
                      />
                    </Box>
                    {!enableOTPInput > 0 ? (
                      <p style={{ fontSize: '1.4rem', color: 'red' }}>
                        An OTP has been sent to your registered email address.
                      </p>
                    ) : (
                      ''
                    )}
                  </Box>
                </form>
              </Box>
            </Grow>
          </div>
        </Modal>
      )}
    </div>
  );
}
