import React, { useEffect, useState } from 'react';
import classes from './EmailVerificationModal.module.css';
import { Box, Grow, Modal } from '@mui/material';
import { FiX } from 'react-icons/fi';
import OtpInput from 'react-otp-input';
import API from '../../../../Axios/axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userProfileDataGet } from '../../../../Store/userProfileSlice';
import { TVPagePostsGet } from '../../../../Store/TVPagePostsSlice';
import { apiErrorCodes } from '../../../../apiErrorCodes';

export default function EmailVerificationModal({
  userEmail,
  closeEmailVerificationModal,
}) {
  const [counter, setCounter] = useState(60);
  const [code, setCode] = useState('');
  const [errorOTPInput, setErrorOTPInput] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOtpVerify = async () => {
    const formData = new FormData();
    formData.append('email', userEmail);
    formData.append('otp', code);
    formData.append('otp_type', 'account__verify');

    try {
      const response = await API.post('/accounts/otp_verify/', formData);
      if (response.data.code === apiErrorCodes.ACCOUNT_VERIFIED) {
        localStorage.setItem('token', response.data.message.token);
        if (response.data.message.profile === false) {
          navigate('/entry/userdetails');
        } else if (response.data.message.creative_interests === false) {
          dispatch(userProfileDataGet());
          navigate('/entry/creativeinterest');
        } else {
          navigate('/');
          dispatch(userProfileDataGet());
          dispatch(TVPagePostsGet());
        }
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.OTP_MISMATCHED ||
        error.response.data.code === apiErrorCodes.EMAIL_NOT_FOUND ||
        error.response.data.code === apiErrorCodes.EMAIL_OTP_REQUIRED ||
        error.response.data.code === apiErrorCodes.OTP_REQUIRED
      ) {
        setErrorOTPInput(true);
      }
    }
  };

  const resendOTP = async () => {
    const formData = new FormData();
    formData.append('email', userEmail);
    try {
      const response = await API.post('/accounts/otp_send/', formData);
      if (response.data.code === apiErrorCodes.SUCCESS) {
        setCounter(60);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      handleOtpVerify(code);
    }
  }, [code]);

  useEffect(() => {
    if (counter > 0) {
      const intervalId = setInterval(() => {
        setCounter((prevCounter) =>
          prevCounter > 0 ? prevCounter - 1 : prevCounter
        );
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [counter]);

  return (
    <Modal open={true}>
      <div>
        <Grow in={true}>
          <Box className={classes.emailVerificationModal}>
            <form autoComplete="off" className={classes.formForgetPassword}>
              <Box className={classes.emailVerificationModalHeader}>
                <p style={{ textAlign: 'center' }}>
                  Please enter the OTP sent to <span>{userEmail}</span>
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '1.4rem',
                    color: '#333',
                    fontStyle: 'italic',
                  }}
                >
                  Note: The email may take a few minutes to arrive.
                  <br />
                  If you don't receive any email, Please check your{' '}
                  <span>SPAM folder.</span>
                </p>
              </Box>
              <Box className={classes.emailVerificationModalBody}>
                <h4>Enter OTP</h4>
                <Box className={classes.emailVerificationModalOTPContainer}>
                  <OtpInput
                    isInputNum
                    inputStyle={{
                      margin: '1rem',
                      height: '2.5rem',
                      width: '2.5rem',
                      border: '1px solid #6e6e6e',
                      borderRadius: ' 0.2rem',
                    }}
                    hasErrored={errorOTPInput}
                    errorStyle={{
                      margin: '1rem',
                      height: '2.5rem',
                      width: '2.5rem',
                      border: '1px solid red',
                      borderRadius: ' 0.2rem',
                    }}
                    numInputs={6}
                    value={code}
                    onChange={setCode}
                  />
                </Box>
                {counter > 0 ? (
                  <h4> Resend OTP in {counter} sec.</h4>
                ) : (
                  <span className={classes.resendOTPButton} onClick={resendOTP}>
                    Resend OTP
                  </span>
                )}
              </Box>
            </form>
          </Box>
        </Grow>
      </div>
    </Modal>
  );
}
