import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../Axios/axios';

export const CREATIVE_INTERESET_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const creativeInterestSlice = createSlice({
  name: 'creativeInterest',
  initialState: {
    creativeInterest: [],
    creativeInterestGetStatus: CREATIVE_INTERESET_GET_STATUSES,
  },
  reducers: {
    // Reducer comes here
  },
  extraReducers: (builder) => {
    builder
      // For User Creative Interests Fetching
      .addCase(creativeInterestGet.pending, (state, action) => {
        state.creativeInterestGetStatus = CREATIVE_INTERESET_GET_STATUSES;
      })
      .addCase(creativeInterestGet.fulfilled, (state, action) => {
        state.creativeInterest = action.payload;
        state.creativeInterestGetStatus = CREATIVE_INTERESET_GET_STATUSES;
      })
      .addCase(creativeInterestGet.rejected, (state, action) => {
        state.creativeInterestGetStatus = CREATIVE_INTERESET_GET_STATUSES;
      });
  },
});

export default creativeInterestSlice.reducer;

// Thunk for TV Card Data Fetching
export const creativeInterestGet = createAsyncThunk(
  'creativeInterestGet',
  async (thunkAPI) => {
    try {
      const response = await API.get('/accounts/creativeinterest/');
      return response.data;
    } catch (e) {
      if (e.response.data.message === 'Invalid token.') {
        localStorage.removeItem('token');
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
