import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Importing stylesheet
import classes from './NavbarLeft.module.css';

// Logo image import
import Logo from '../../../Assets/Logo/logo.svg';

//Search input UI component Import
import SearchInput from './SearchInput/SearchInput';
//only test if search fixed
import { useViewport } from '../../../CustomHooks/useViewport';
import { useDispatch } from 'react-redux';
// Thunk
import { TVPagePostsGet } from '../../../Store/TVPagePostsSlice';
import { closeEventPosts } from '../../../Store/eventsSlice';

export default function NavbarLeft() {
  // custom hook to get viewport width
  const { width } = useViewport();
  const breakpoint = 600; // this break point will toggle different view components
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = useState('');

  const onChangeHandler = (e) => {
    setValue(e.target.value);
    if (e.target.value === null || e.target.value === '') {
      if (location.pathname == '/') {
        dispatch(TVPagePostsGet());
      }
    }
  };

  return (
    <div className={classes.left}>
      <div
        className={classes.logoContainer}
        onClick={() => {
          navigate('/');
          dispatch(closeEventPosts());
          dispatch(TVPagePostsGet());
        }}
      >
        <img
          draggable="false"
          src={Logo}
          alt="Srijcon Logo"
          className={classes.logo}
        />
        <p className={classes.logoText}>Srijcon </p>
      </div>

      <div className={classes.search}>
        {/* {!tabPort && (
          <SearchInput
            data={{ type: 'text' }}
            // width="40rem"
            placeholder='Search Srijcon'
          />
        )} */}
        {width > breakpoint ? (
          <SearchInput
            data={{ type: 'text' }}
            // width="40rem"
            placeholder="Search Srijcon"
            value={value}
            onChange={(e) => onChangeHandler(e)}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
