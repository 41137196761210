import React from 'react';
import classes from './FanAndFavNotificationTemplate.module.css';
import {Avatar, Badge} from '@mui/material';
import calcTimeDifference from '../../../../../../HelperFunctions/calcTimeDifference';
import shortenFileName from "../../../../../../HelperFunctions/shortenFileName";

const FanAndFavNotificationTemplate = ({item, onClickHandler}) => {
  const {updated_at, message, is_seen, name, id, profile} = item;
  return (
    <div className={classes.notificationContainer}>
      <div
        className={classes.textContainer}
        key={id}
        onClick={() => onClickHandler(item)}
      >
        <Avatar
          sx={{width: 36, height: 36}}
          src={profile}
          alt="avatar_img"
        />
        <div className={classes.text}>
          <p>
            <span className={classes.name}>{shortenFileName(name, 20)}</span>{' '}
            <span className={classes.message}>{message}</span>
          </p>
          <p className="element-description">
            {calcTimeDifference(updated_at)}
          </p>
        </div>
      </div>
      <div className={classes.iconContainer}>
        <Badge
          color="error"
          overlap="circular"
          variant="dot"
          invisible={is_seen}
        ></Badge>
      </div>
    </div>
  );
};

export default FanAndFavNotificationTemplate;
