import React from 'react';
// Grow transition Import from Material UI
import { Grow } from '@mui/material';

// Stylesheet import
import classes from './UserProfileAvatarDropdown.module.css';

import { useNavigate } from 'react-router-dom';

import { logout } from '../../../../Store/userProfileSlice';

// Redux dispatch to dispatch user credentials
import { useDispatch, useSelector } from 'react-redux';
// Thunk
// Icons import from Feather icon
import { Power as Logout, Settings, User as Profile } from 'react-feather';
//end button for drop down in responsiveness
import { FiX } from 'react-icons/fi';
import API from '../../../../Axios/axios';
import { apiErrorCodes } from '../../../../apiErrorCodes';
import shortenFileName from "../../../../HelperFunctions/shortenFileName";

export default function UserProfileAvatarDropdown(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const { userProfileData } = useSelector((state) => state.userProfileSlice);

  const navigate = useNavigate();
  const signout = async () => {
    try {
      const response = await API.delete('/accounts/logout', {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      if (response.data.code === apiErrorCodes.SUCCESS) {
        navigate('/entry/signin');
        localStorage.clear();
        dispatch(logout());
        props.setIsUserProfileAvatarDropdownActive(false);
      }
    } catch (e) {
      if (
        e.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        e.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  return (
    <Grow in={true}>
      <div className={classes.dropdown}>
        <div className={classes.header}>
          <h4 className="component-heading">
            {shortenFileName(userProfileData.full_name, 30)}
          </h4>
          <FiX className={classes.fix} />
        </div>
        <div className={classes.container}>
          <div
            className={classes.item}
            onClick={() => {
              navigate('/userprofile');
              props.setIsUserProfileAvatarDropdownActive(false);
            }}
          >
            <Profile className={classes.icon} />
            <h4 className="element-heading element-profile">Profile</h4>
          </div>

          <div
            className={classes.item}
            onClick={() => {
              navigate('/settings');
              props.setIsUserProfileAvatarDropdownActive(false);
            }}
          >
            <Settings className={classes.icon} />
            <h4 className="element-heading">Settings</h4>
          </div>
          <div className={classes.item} onClick={signout}>
            <Logout className={classes.icon} />
            <h4 className="element-heading">Sign Out</h4>
          </div>
        </div>
      </div>
    </Grow>
  );
}
