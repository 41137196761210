import React from 'react';
import { Box, Grow, Modal } from '@mui/material';
import classes from './SuccessMessageModal.module.css';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export default function SuccessMessageModal(props) {
  setTimeout(() => {
    props.setSuccessMessageModal(false);
    props.handleClose();
  }, 2000);
  const handleClose = () => {
    props.setSuccessMessageModal(false);
    props.handleClose();
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <div>
        <Grow in={true}>
          <Box className={classes.SuccessMessageModal}>
            <Box className={classes.SuccessMessageModalBox}>
              <CheckCircleRoundedIcon className={classes.checkCircle} />
              <p className={classes.SuccessMessageModalText}>
                Your password has been successfully updated.
              </p>
            </Box>
          </Box>
        </Grow>
      </div>
    </Modal>
  );
}
