// Combine reducer to combine all reducers import from Redux
import { combineReducers } from 'redux';

// Importing all Reducers
import creativeInterestSlice from './creativeInterestSlice';
import userProfileSlice from './userProfileSlice';
import TVPagePostsSlice from './TVPagePostsSlice';
import featuredCreatorSlice from './featuredCreatorSlice';
import creatorProfileSlice from './creatorProfileSlice';
import eventsSlice from './eventsSlice';
import singlePostSlice from './singlePostSlice';
import notificationSlice from './notificationSlice';
import uploadContentSlice from './uploadContentSlice';
import videoPreferenceSlice from './videoPreferenceSlice';
const rootReducer = combineReducers({
  creativeInterestSlice,
  userProfileSlice,
  TVPagePostsSlice,
  featuredCreatorSlice,
  creatorProfileSlice,
  eventsSlice,
  singlePostSlice,
  notificationSlice,
  uploadContentSlice,
  videoPreferenceSlice,
});

export default rootReducer;
