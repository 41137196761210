// Stylesheet import
import classes from './SearchInput.module.css';

// Icon import from Feather icon
import { Search as SearchIcon } from 'react-feather';
// Thunk
import { searchPostsGet } from '../../../../Store/TVPagePostsSlice';
// Redux dispatch to dispatch user credentials
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SearchInput = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(searchPostsGet(props.value)).then(() =>
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    );
    navigate('/');
  };

  return (
    <form
      onSubmit={onSubmit}
      autoComplete="off"
      className={classes.form}
      style={{
        width: props.width,
        display: props.display,
        justifyContent: props.justify,
      }}
    >
      <input
        type={props.data.type}
        className={classes.input}
        placeholder={props.placeholder}
        autoFocus={props.showClose}
        // style={{ minWidth: props.width }}
        onChange={props.onChange}
        value={props.value}
        name="search"
      />
      <SearchIcon
        className={classes.icon}
        sx={{ fontSize: 25, color: '#b9b9b9' }}
        style={{ left: `${props.left}` }}
      />

      {/* {props.showClose && (
        <Close
          sx={{ fontSize: 18, color: '#b9b9b9' }}
          onClick={props.closeInput}
          className={classes.close}
        />
      )} */}
    </form>
  );
};

SearchInput.defalutProps = {
  showClose: false,
};

export default SearchInput;
