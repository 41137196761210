import React, { useEffect } from 'react';

// React Router necessary imports
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// importing components
import Settings from './Components/Settings/Settings';
import TVPage from './Components/TVPage/TVPage';
import EditProfileSection from './Components/UserProfile/EditProfileSection/EditProfileSection';
import UserProfile from './Components/UserProfile/UserProfile';
import CreatorProfile from './Components/UserProfile/CreatorProfile';
import UploadContent from './Components/Navbar/NavbarRight/ContentTypeModal/UploadContentModal/UploadContent';
import TermsAndConditionsPage from './Components/Settings/TermsAndConditionsPage/TermsAndConditionsPage';
import CreativeInterests from './Components/Navbar/NavbarRight/CreativeIntersts/CreativeInterests';
import WithoutNav from './Components/WithoutNav';
import WithNav from './Components/WithNav';
import Protected from './Components/Protected';
// import { LandingPage } from './Components/LandingPageNew/LandingPage';
import LandingPage from './Components/LandingPageNew/LandingPage';
import SignIn from './Components/Entry/SignIn/SignIn';
import SignUp from './Components/Entry/SignUp/SignUp';
import UserDetails from './Components/Entry/UserDetails/UserDetails';
import PageNotFound from './Components/PageNotFound/PageNotFound';
// Thunk
import {
  newNotifications,
  USER_PROFILE_DATA_GET_STATUSES,
  userProfileDataGet,
} from './Store/userProfileSlice';
import {
  TV_PAGE_POSTS_GET_STATUSES,
  TVPagePostsGet,
} from './Store/TVPagePostsSlice';
import { FEATURED_CREATOR_GET_STATUSES } from './Store/featuredCreatorSlice';

// Redux dispatch to dispatch user credentials
import { useDispatch, useSelector } from 'react-redux';
import SinglePost from './Components/SinglePost/SinglePost';
import createWebSocket from './WebSocket';
import { liveNotificationAdd } from './Store/notificationSlice';
import { Oval } from  'react-loader-spinner'

function App() {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const { userProfileDataGetStatus } = useSelector(
    (state) => state.userProfileSlice
  );
  const { TVPagePostsGetStatus } = useSelector(
    (state) => state.TVPagePostsSlice
  );
  const { featuredCreatorGetStatus } = useSelector(
    (state) => state.featuredCreatorSlice
  );
  const { userProfileData } = useSelector((state) => state.userProfileSlice);

  useEffect(() => {
    if (token) {
      dispatch(TVPagePostsGet());
      dispatch(userProfileDataGet()).then((response) => {
        const socket = createWebSocket(response.payload.id);

        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message?.notification?.code === 'new__notification') {
            dispatch(newNotifications(true));
            dispatch(liveNotificationAdd(message.notification.details));
          }
        };

        return () => {
          socket.close();
        };
      });
    }
  }, []);

  if (
    userProfileDataGetStatus === USER_PROFILE_DATA_GET_STATUSES.LOADING ||
    TVPagePostsGetStatus === TV_PAGE_POSTS_GET_STATUSES.LOADING ||
    featuredCreatorGetStatus === FEATURED_CREATOR_GET_STATUSES.Loading
  ) {
    return <Oval
    height={80}
    width={80}
    color="#d11414"
    wrapperStyle={{
      position: 'fixed',
      display: 'flex',
      width: '100%',
      height:'100vh',
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(1px)',
      zIndex: '1',
    }}
    ariaLabel='oval-loading'
    secondaryColor="#d11414"
    strokeWidth={3}
    visible={true}
    strokeWidthSecondary={2} />;
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          {localStorage.getItem('token') ? (
            <>
              <Route element={<WithNav />}>
                <Route path="/" element={<TVPage />} />
                <Route
                  path="/settings"
                  element={<Protected Component={Settings} />}
                />
                <Route
                  path="/creativeinterest"
                  element={<Protected Component={CreativeInterests} />}
                />
                <Route
                  path="/uploadcontent/:name"
                  element={<Protected Component={UploadContent} />}
                />
                <Route
                  path="/userprofile"
                  element={<Protected Component={UserProfile} />}
                />
                <Route
                  path="/accounts/:name/profile/"
                  element={<CreatorProfile />}
                />
                <Route
                  path="/editprofile"
                  element={<Protected Component={EditProfileSection} />}
                />{' '}
                <Route
                  path="/post/:postId/"
                  element={<Protected Component={SinglePost} />}
                />
              </Route>
              <Route element={<WithoutNav />}>
                <Route path="/entry/signin" element={<SignIn />} />
                <Route path="/entry/signup" element={<SignUp />} />
                <Route
                  path="/entry/userdetails"
                  element={<Protected Component={UserDetails} />}
                />
                <Route
                  path="/entry/creativeinterest"
                  element={<Protected Component={CreativeInterests} />}
                />
                <Route
                  path="/termsandconditions"
                  element={<TermsAndConditionsPage />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </>
          ) : (
            <Route element={<WithoutNav />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="/entry/signin" element={<SignIn />} />
              <Route path="/entry/signup" element={<SignUp />} />
              <Route
                path="/termsandconditions"
                element={<TermsAndConditionsPage />}
              />
              <Route path="/post/:postId/" element={<SinglePost />} />
              <Route
                path="/entry/userdetails"
                element={<Protected Component={UserDetails} />}
              />
              <Route
                path="/entry/creativeinterest"
                element={<Protected Component={CreativeInterests} />}
              />
              <Route
                path="/accounts/:name/profile/"
                element={<Protected Component={CreatorProfile} />}
              />
              <Route
                path="/settings"
                element={<Protected Component={Settings} />}
              />
              <Route
                path="/userprofile"
                element={<Protected Component={UserProfile} />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
