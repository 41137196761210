import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
// Card component import
import Card from '../../../CommonComponents/Card/Card';
import { Modal, Box } from '@mui/material';
// import UploadContentModal from './UploadContentModal/UploadContent';

// Stylesheet import
import classes from './ContentTypeModal.module.css';


// Content Modals
import TitleModal from './UploadContentModal/UploadContent/TitleModal/TitleModal';
import MediaModal from './UploadContentModal/UploadContent/MediaModal/MediaModal';
import DetailsModal from './UploadContentModal/UploadContent/DetailsModal/DetailsModal';
import { useDispatch, useSelector } from 'react-redux';
import { handleContentType, handleInitialize, handleModalType } from '../../../../Store/uploadContentSlice';
import AttachFilesModal from './UploadContentModal/UploadContent/AttachFilesModal/AttachFilesModal';
import { useContext } from 'react';
import UploadContentContext from '../../../../Context/UploadContent/uploadContentContext';
// import UploadContentState from '../../../../Context/UploadContent/uploadContentState';
export default function SelectContentDropdown(props) {

   const dispatch = useDispatch();
   const modalType = useSelector((state) => state.uploadContentSlice.modalType);
   const { handleReset } = useContext(UploadContentContext);
   const [open, setOpen] = useState(true);

    
  const handleUploadContent = (modalType, contentType) => {
    dispatch(handleModalType(modalType));
    dispatch(handleContentType(contentType));
    setOpen(false);
  };
  const contentTypeModalClose = () => {
    dispatch(handleInitialize());
    handleReset();
    setOpen(false);
    props.setIsContentTypeDropdownActive(false);
    props.setActiveDropdown(null);
  };

  const handleUploadContentClose = () => {
    dispatch(handleInitialize())
    handleReset();
    props.setIsContentTypeDropdownActive(false);
    props.setActiveDropdown(null);
  }
  return (
    <>
      {/* {showUploadContentModal && <UploadContentModal />} */}
      {modalType === 'title' && (
        <TitleModal onClose={handleUploadContentClose} />
      )}
      {modalType === 'media' && (
        <MediaModal onClose={handleUploadContentClose} />
      )}
      {modalType === 'details' && (
        <DetailsModal onClose={handleUploadContentClose} />
      )}
      {modalType === 'attachments' && (
        <AttachFilesModal onClose={handleUploadContentClose} />
      )}
      {modalType === 'contentType' && (
        <Modal open={true} onClose={contentTypeModalClose}>
          <Card>
            <div>
              <Box className={classes.popup}>
                <div className={classes.container}>
                  <div className={classes.header}>
                    <FiX
                      className="cross-button"
                      onClick={contentTypeModalClose}
                    />
                    <h4 className="component-heading">Showcase Your...</h4>
                  </div>
                </div>
                <div className={classes.container}>
                  <div
                    className={classes.item}
                    onClick={() => handleUploadContent('title', 'Project')}
                  >
                    <h4 className="element-heading">Project</h4>
                  </div>
                  <div
                    className={classes.item}
                    onClick={() =>
                      handleUploadContent('media', 'Creative Work')
                    }
                  >
                    <h4 className="element-heading">Creative Work</h4>
                  </div>
                  <div
                    className={classes.item}
                    onClick={() => handleUploadContent('media', 'Innovation')}
                  >
                    <h4 className="element-heading">Innovation</h4>
                  </div>
                  <div
                    className={classes.item}
                    onClick={() =>
                      handleUploadContent('media', 'STEM Creations')
                    }
                  >
                    <h4 className="element-heading">STEM Creations</h4>
                  </div>
                </div>
              </Box>
            </div>
          </Card>
        </Modal>
      )}
    </>
  );
}
