import {countCharacters} from "../countCharacters";

export const isValidAttachment = (attachmentFile) => {
  const allowedExtensions = /(\.doc|\.docx|\.rtf|\.xls|\.xlsx|\.ppt|\.pptx|\.txt|\.pdf)$/i;
  // For single image upload
  if (!allowedExtensions.exec(attachmentFile.name)) {
    return 'UNSUPPORTED_ATTACHMENT_TYPE';
  } else {
    if (countCharacters(attachmentFile.name) > 1000) {
      return 'UNSUPPORTED_FILE_NAME';
    } else {
      return null;
    }
  }
}