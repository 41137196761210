// Stylesheet import
import classes from './NotificationDropdown.module.css';

// Grow transition Import from Material UI
import { Grow } from '@mui/material';
import { FiMoreVertical as MoreIcon } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

// notification templates imported
import DiscussionNotificationTemplate from './NotificationTemplates/DiscussionNotificationTemplate/DiscussionNotificationTemplate';
import ReactionNotificationTemplate from './NotificationTemplates/ReactionNotificationTemplate/ReactionNotificationTemplate';
import FanAndFavNotificationTemplate from './NotificationTemplates/FanAndFavNotificationTemplate/FanAndFavNotificationTemplate';

// use-navigate imported for navigation
import { useNavigate } from 'react-router-dom';

// reducer imported for notification seen status change
import {
  markNotificationAsRead,
  newNotificationAdd,
} from '../../../../Store/notificationSlice';
import API from '../../../../Axios/axios';
import { apiErrorCodes } from '../../../../apiErrorCodes';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoNewNotificationTemplate from './NotificationTemplates/NoNewNotificationTemplate/NoNewNotificationTemplate';
import React, { useContext, useEffect, useState } from 'react';
import { newNotifications } from '../../../../Store/userProfileSlice';
import MarkAllAsReadDropdown from './MarkAllAsReadDropdown/MarkAllAsReadDropdown';
import bottomNavbarContext from '../../../../Context/Navbar/bottomNavbarContext';

const NotificationDropdown = function ({ handleClickAwayNotification }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notificationData } = useSelector((state) => state.notificationSlice);
  const [openMarkAllAsReadDropdown, setOpenMarkAllAsReadDropdown] =
    useState(false);
  const { isNotificationActive, notificationClickAwayHandler } =
    useContext(bottomNavbarContext);
  const onClickHandler = async (data) => {
    const { id, link, is_seen, action_url } = data;
    if (isNotificationActive) {
      notificationClickAwayHandler();
    } else {
      handleClickAwayNotification();
    }
    navigate(link);
    if (!is_seen) {
      try {
        const response = await API.patch(
          `${action_url}`,
          {},
          {
            headers: {
              Authorization: `token ${localStorage.getItem('token')}`,
            },
          }
        );
        if (response.data.code === apiErrorCodes.SUCCESS) {
          dispatch(markNotificationAsRead(id));
        }
      } catch (error) {
        if (
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    }
  };
  const dropdownElements =
    notificationData?.results?.length > 0 ? (
      notificationData?.results?.map((item) => {
        switch (item.category) {
          case 'post_reactions':
            return (
              <ReactionNotificationTemplate
                key={item.id}
                item={item}
                onClickHandler={onClickHandler}
              />
            );
          case 'post_discussions':
            return (
              <DiscussionNotificationTemplate
                key={item.id}
                item={item}
                onClickHandler={onClickHandler}
              />
            );
          case 'user_fan_favourite':
            return (
              <FanAndFavNotificationTemplate
                key={item.id}
                item={item}
                onClickHandler={onClickHandler}
              />
            );
          default:
            throw new Error('Unknown notification category');
        }
      })
    ) : (
      <NoNewNotificationTemplate />
    );

  const getCommentData = async () => {
    try {
      const response = await axios.get(`${notificationData.urls.next}`, {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      dispatch(newNotificationAdd(response.data.message));
    } catch (error) {
      if (error.response.data.code === apiErrorCodes.NOT_FOUND) {
      } else if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
      }
    }
  };

  useEffect(() => {
    dispatch(newNotifications(false));
  }, []);
  const moreClickHandler = () => {
    setOpenMarkAllAsReadDropdown(!openMarkAllAsReadDropdown);
  };

  return (
    <Grow in={true}>
      <div className={classes.dropdown}>
        <div className={classes.header}>
          <h4 className="component-heading">Notifications</h4>
          <MoreIcon className={classes.moreIcon} onClick={moreClickHandler} />
          {openMarkAllAsReadDropdown && (
            <MarkAllAsReadDropdown
              setOpenMarkAllAsReadDropdown={setOpenMarkAllAsReadDropdown}
            />
          )}
        </div>
        <div className={classes.container} id="scrollableDiv">
          <InfiniteScroll
            dataLength={notificationData.results.length}
            next={getCommentData}
            hasMore={notificationData?.urls?.next !== null}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {dropdownElements}
          </InfiniteScroll>
        </div>
      </div>
    </Grow>
  );
};
export default NotificationDropdown;
