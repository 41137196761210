import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../Axios/axios';
import { apiErrorCodes } from '../apiErrorCodes';
//! Image import for development purpose: discussion Section

export const NOTIFICATION_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});
// const initialState = {
//   notificationData: {
//     count: 5,
//     notifications: [
//       {
//         id: 1,
//         link: '/post/eaa67933-19fb-4cab-be86-9ee600a1f61c/',
//         name: 'Rishabh Shukla',
//         message: 'finds your content inspiring.',
//         created_at: '2023-03-16T17:54:19.630000+05:30',
//         isSeen: false,
//         profile: avatar1,
//         category: 'reactions',
//       },
//       {
//         id: 2,
//         link: '/post/eaa67933-19fb-4cab-be86-9ee600a1f61c/',
//         name: 'Rishabh Shukla',
//         message: 'discussed in your post.',
//         created_at: '2023-03-15T22:16:39.173000+05:30',
//         isSeen: false,
//         profile: avatar2,
//         category: 'discussions',
//       },
//       {
//         id: 3,
//         link: '/post/eaa67933-19fb-4cab-be86-9ee600a1f61c/',
//         name: 'Rishabh Shukla and 13 others',
//         message: 'reacted on your content.',
//         created_at: '2023-01-12T19:35:02+05:30',
//         isSeen: false,
//         profile: avatar3,
//         category: 'reactions',
//       },
//       {
//         id: 4,
//         link: '/accounts/b083b2c8-e279-4e21-ba1a-cfa6ce639221/profile/',
//         name: 'Shivam Singh Chauhan',
//         message: 'has became your fan.',
//         created_at: '2023-01-12T19:35:02+05:30',
//         isSeen: false,
//         profile: avatar4,
//         category: 'fans_and_favourites',
//       },
//       {
//         id: 5,
//         link: 'accounts/b083b2c8-e279-4e21-ba1a-cfa6ce639221/profile/',
//         name: 'jagjot singh budhraj and 100 others',
//         message: 'discussed on your content.',
//         created_at: '2023-01-12T19:35:02+05:30',
//         isSeen: false,
//         profile: avatar5,
//         category: 'discussions',
//       },
//     ],
//   },
// };

export const notificationSlice = createSlice({
  name: 'events',
  initialState: {
    notificationData: [],
    notificationDataGetStatus: NOTIFICATION_GET_STATUSES.IDLE,
  },
  reducers: {
    liveNotificationAdd: (state, action) => {
      const liveNotification = action.payload;
      const updatedResults = [
        liveNotification,
        ...state.notificationData.results,
      ];
      const updatedNotificationData = {
        ...state.notificationData,
        results: updatedResults,
      };
      return { ...state, notificationData: updatedNotificationData };
    },
    markNotificationAsRead: (state, action) => {
      const notification = state.notificationData.results.find(
        (notification) => notification.id === action.payload
      );
      notification.is_seen = true;
      return state;
    },
    markAllAsRead: (state, action) => {
      // Set all notifications to be seen.
      state.notificationData.results.forEach((notification) => {
        notification.is_seen = true;
      });
      return state;
    },
    newNotificationAdd: (state, action) => {
      const newNotifications = action.payload.results;
      const newURL = action.payload.urls;
      const updatedResults = [
        ...state.notificationData.results,
        ...newNotifications,
      ];
      const updatedNotificationData = {
        ...state.notificationData,
        urls: newURL,
        results: updatedResults,
      };
      return { ...state, notificationData: updatedNotificationData };
    },
  },
  extraReducers: (builder) => {
    builder
      // For notification Fetching
      .addCase(notificationGet.pending, (state, action) => {
        state.notificationDataGetStatus = NOTIFICATION_GET_STATUSES.LOADING;
      })
      .addCase(notificationGet.fulfilled, (state, action) => {
        state.notificationData = action.payload;
        state.notificationDataGetStatus = NOTIFICATION_GET_STATUSES.IDLE;
      })
      .addCase(notificationGet.rejected, (state, action) => {
        state.notificationDataGetStatus = NOTIFICATION_GET_STATUSES.ERROR;
      });
  },
});
export const {
  liveNotificationAdd,
  markNotificationAsRead,
  markAllAsRead,
  newNotificationAdd,
} = notificationSlice.actions;
export default notificationSlice.reducer;

// Thunk for TV Card Data Fetching using search bar
export const notificationGet = createAsyncThunk(
  'notificationGet',
  async (data, thunkAPI) => {
    try {
      const response = await API.get('/notifications/', {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        return response.data.message;
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
