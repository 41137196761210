import React from 'react';
import classes from './FileDisplayBlock.module.css';
// Icon import from Feather icon
import {Trash as Delete} from 'react-feather';
import shortenFileName from "../../../../../../../../HelperFunctions/shortenFileName";

const FileDisplayBlock = (props) => {
  return (
    <div className={classes.fileBox}>
      <div className={`element-heading ${classes.elementHeading}`}>{shortenFileName(props.file.name, 50)}</div>
      <Delete
        className={classes.icon}
        onClick={() => props.handleDelete(props.file)}
      />
    </div>
  );
};

export default FileDisplayBlock;
