import React from 'react';
import classes from './MarkAllAsReadDropdown.module.css';
import { Grow } from '@mui/material';
import API from '../../../../../Axios/axios';
import { useNavigate } from 'react-router-dom';
import { apiErrorCodes } from '../../../../../apiErrorCodes';
import { markAllAsRead } from '../../../../../Store/notificationSlice';
import { useDispatch } from 'react-redux';

const MarkAllAsReadDropdown = ({ setOpenMarkAllAsReadDropdown }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  const handleMarkAllAsReadClick = async () => {
    try {
      const response = await API.patch(
        `/notifications/read_all_or_delete_all`,
        {},
        {
          headers: { Authorization: `token ${token}` },
        }
      );
      if (response.data.code === apiErrorCodes.SUCCESS) {
        setOpenMarkAllAsReadDropdown(false);
        dispatch(markAllAsRead());
      }
    } catch (error) {
      if (error.response.data.message === 'Invalid token.') {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
    }
  };

  return (
    <Grow in={true}>
      <div className={classes.dropdown}>
        <div className={classes.element} onClick={handleMarkAllAsReadClick}>
          <p style={{ display: 'contents' }} className="element-heading">
            Mark all as read
          </p>
        </div>
      </div>
    </Grow>
  );
};

export default MarkAllAsReadDropdown;
