import React from 'react'
import BottomNavbarState from './Navbar/bottomNavbarState'
import UploadContentState from './UploadContent/uploadContentState'

const compose = (providers) =>
providers.reduce((Prev, Curr) => ({ children }) => (
    <Prev>
        <Curr>{children}</Curr>
    </Prev>
));

const AppContextProvider = compose([
    BottomNavbarState,
    UploadContentState,
]);

export default AppContextProvider;