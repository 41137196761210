import React from 'react';
import classes from './PageNotFound.module.css';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className={classes.mainDiv}>
      <div className={classes.flexContainer}>
        <h1 style={{ fontSize: '2rem' }} className="element-heading">
          404 | This page could not be found.
        </h1>
      </div>
      <button className={classes.homeButton} onClick={() => navigate('/')}>
        Go to Home
      </button>
    </div>
  );
}
