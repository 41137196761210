import React, { useEffect, useState } from 'react';
import classes from './SinglePost.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import TVCard from '../TVPage/TVCard/TVCard';
import logo from '../../Assets/Logo/logo.svg';
import PageNotFound from '../PageNotFound/PageNotFound';
import { singlePostDataGet } from '../../Store/singlePostSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function SinglePost() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postId } = params;
  const [postData, setPostData] = useState('');
  const [status, setStatus] = useState('loading');
  const { singlePostData } = useSelector((state) => state.singlePostSlice);

  useEffect(() => {
    dispatch(singlePostDataGet(postId)).then((response) => setStatus('Done'));
  }, [postId]);

  useEffect(() => {
    setPostData(singlePostData);
  }, [singlePostData]);

  if (status === 'loading') {
    return '';
  }
  if (status === 'error') {
    return <PageNotFound />;
  }

  return (
    <>
      {/*<Helmet>*/}
      {/*  {postData.project_title !== null ? (*/}
      {/*    <title>{`${postData.creator.name} | ${postData.project_title} | Srijcon`}</title>*/}
      {/*  ) : (*/}
      {/*    <title>{`${postData.creator.name} | Srijcon`}</title>*/}
      {/*  )}*/}
      {/*  <link rel="canonical" href="/" />*/}
      {/*  <meta name="description" content={`${postData.text}`} />*/}
      {/*</Helmet>*/}
      {localStorage.getItem('token') ? (
        ''
      ) : (
        <div className={classes.navbar}>
          <div className={classes.logoContainer}>
            <img
              draggable="false"
              src={logo}
              alt="Srijcon Logo"
              className={classes.logo}
            />
            <p className={classes.logoText}>Srijcon </p>
          </div>
          <div>
            <button
              className={classes.signInButton}
              onClick={() => navigate('/entry/signin')}
            >
              Sign In
            </button>
          </div>
        </div>
      )}
      <div className={classes.tvPage}>
        <main className={classes.main}>
          <TVCard postData={postData} postType="single"/>
        </main>
      </div>
    </>
  );
}
