import React from 'react';

import { Outlet } from 'react-router';
import BottomNavbar from './Navbar/BottomNavbar/BottomNavbar';
import Navbar from './Navbar/Navbar';
import { useViewport } from "../CustomHooks/useViewport";
export default () => {
    const { width } = useViewport();
    const breakpoint = 600;
    return (
        <>
            <Navbar />
            {width < breakpoint ? <BottomNavbar /> : ''} 
            <Outlet />
        </>
    );
};