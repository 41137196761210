import React, {useContext, useRef, useState} from 'react';

import {Collapse, Modal} from '@mui/material';

//import icons
import {FiArrowLeft, FiArrowUpCircle, FiX} from 'react-icons/fi';

//import css
import classes from './AttachFilesModal.module.css';

// Error modals
import CloseWarningModal from '../../ErrorModals/CloseWarningModal/CloseWarningModal';
import MaxSelectFileErrorModal from '../../ErrorModals/MaxSelectFileErrorModal/MaxSelectFileErrorModal'
// Displaying Files
import FileDisplayBlock from './AttachFilesComponents/FileDisplayBlock';

// redux reducers
import {handleModalType} from '../../../../../../../Store/uploadContentSlice';

import {useDispatch} from 'react-redux';

// Context
import UploadContentContext from '../../../../../../../Context/UploadContent/uploadContentContext';
import {TransitionGroup} from 'react-transition-group';
import {isValidAttachment} from "../../../../../../../HelperFunctions/checkValidity/isValidAttachment";
import UnsupportedFileModal from "../../ErrorModals/UnsupportedFileModal/UnsupportedFileModal";

const AttachFilesModal = (props) => {
  const inputFileRef = useRef(null);
  const dispatch = useDispatch();

  const [unsupportedFile, setUnsupportedFile] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [closeWarning, setCloseWarning] = useState(false);
  const [errorType, setErrorType] = useState(null);

  const {attachments, setAttachments} = useContext(UploadContentContext);

  const handleModalClose = () => {
    setCloseWarning(true);
  };

  const handleBack = () => {
    dispatch(handleModalType('details'));
  };

  const handleUpload = (e) => {
    if (e.target.files) {
      Array.from(e.target.files).forEach((file) => {
        const validity = isValidAttachment(file);
        if (validity !== null) {
          setUnsupportedFile(true);
          setErrorType(validity);
        } else {
          try {
            if (attachments.length + e.target.files.length > 10) {
              setLimitError(true);
            } else {
              setAttachments((current) => [...current, ...e.target.files]);
            }
          } catch (e) {
            console.log(e);
          } finally {
            e.target.value = null;
          }
        }
      });
    }
  };

  const handleDelete = (deleteFile) => {
    setAttachments((files) => files.filter((file) => {
      return file !== deleteFile;
    }));
  };
  return (<>
    {unsupportedFile && (
      <UnsupportedFileModal
        setUnsupportedFileModal={setUnsupportedFile}
        errorType={errorType}
      />
    )}
    {closeWarning && (<CloseWarningModal
      setCloseWarning={setCloseWarning}
      onClose={props.onClose}
    />)}
    {limitError && (<MaxSelectFileErrorModal setMaxSelectFileErrorModal={setLimitError}/>)}
    <Modal
      open={true}
      // onClose={() => handleModalClose()}
      className={classes.modal}
    >
      {/*<Grow in={true}>*/}
      <div className={classes.wholeModal}>
        <div className={classes.modalHeading}>
          <FiArrowLeft
            onClick={handleBack}
            style={{margin: 0}}
            className="cross-button"
          />
          <p className="component-heading">FILE ATTACHMENTS</p>
          <FiX
            className="cross-button"
            style={{margin: 0}}
            onClick={handleModalClose}
          />
        </div>
        <div className={classes.innerModal}>
          <div className={classes.UploadMediaContainer}>
            <input
              ref={inputFileRef}
              hidden
              multiple
              accept=".doc, .docx, .rtf, .xls, .xlsx, .ppt, .pptx, .pdf, .txt"
              id="file-upload"
              type="file"
              onChange={handleUpload}
            />
            <button onClick={() => inputFileRef.current.click()}>
              <FiArrowUpCircle className={classes.uploadIcon}/>
              <h2 className={classes.descriptionText}>
                Upload Document Files
              </h2>
              <br/>
              <p className='element-description'>
                Only doc, docx, rtf, xls, xlsx, ppt, pptx, pdf, txt files are allowed.
              </p>
            </button>
          </div>
          <div className={classes.insideEditModal}>
            <TransitionGroup>
              {attachments.map((file, key) => (<Collapse key={key}>
                <FileDisplayBlock
                  key={key}
                  file={file}
                  handleDelete={handleDelete}
                />
              </Collapse>))}
            </TransitionGroup>
          </div>

          {/*<div className={classes.saveButtonContainer}>*/}
          {/*  <button className={classes.btnSave} onClick={handleBack}>*/}
          {/*    Save*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      </div>
      {/*</Grow>*/}
    </Modal>
  </>);
};

export default AttachFilesModal;


