import React from 'react';
import classes from './NoNewNotificationTemplate.module.css';

const NoNewNotificationTemplate = () => {
  return (
    <div className={classes.mainContainer}>
      <h1 className={classes.name}>No notifications yet</h1>
    </div>
  );
};

export default NoNewNotificationTemplate;
