import React, {useState} from 'react'
import UploadContentContext from './uploadContentContext';
const UploadContentState = (props) => {
    const [videoBlobToFile, setVideoBlobToFile] = useState({});
    const [attachments, setAttachments] = useState([]);
    const handleReset = () => {
        setVideoBlobToFile({});
        setAttachments([]);
    }

    return (
        <UploadContentContext.Provider
            value={{
                attachments,
                setAttachments,
                videoBlobToFile,
                setVideoBlobToFile,
                handleReset,
            }}>
            {props.children}
        </UploadContentContext.Provider>
    )
}

export default UploadContentState
