import React, { useState } from 'react';
import { Box, Grow, Modal } from '@mui/material';

import { FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import style from './AccountNotRegisteredModal.module.css';

export default function AccountNotRegisteredModal(props) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.setAccountNotRegisteredModal(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div>
          <Grow in={true}>
            <Box className={style.AccountNotRegisteredModal}>
              <FiX className="cross-button" onClick={handleClose} />
              <Box className={style.AccountNotRegisteredModalBox}>
                <p className={style.AccountNotRegisteredText}>
                  Your account is not registered.
                </p>
                <p className={style.signUPText}>
                  Please go to{' '}
                  <Link
                    style={{ color: '#b6193a', textDecoration: 'none' }}
                    to="/entry/signup"
                    onClick={() => {
                      setOpen(false);
                      props.setAccountNotRegisteredModal(false);
                    }}
                  >
                    Signup.
                  </Link>
                </p>
              </Box>
            </Box>
          </Grow>
        </div>
      </Modal>
    </>
  );
}
