import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../Axios/axios';
import { apiErrorCodes } from '../apiErrorCodes';

export const CREATOR_PROFILE_DATA_GET_STATUSES = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});
export const creatorProfileSlice = createSlice({
  name: 'creatorProfile',
  initialState: {
    creatorProfileData: [],
    creatorProfileDataGetStatus: CREATOR_PROFILE_DATA_GET_STATUSES.IDLE,
  },
  reducers: {
    // Reducer comes here
  },
  extraReducers: (builder) => {
    builder
      // For User Profile Deta Fetching
      .addCase(creatorProfileDataGet.pending, (state, action) => {
        state.creatorProfileDataGetStatus =
          CREATOR_PROFILE_DATA_GET_STATUSES.LOADING;
      })
      .addCase(creatorProfileDataGet.fulfilled, (state, action) => {
        state.creatorProfileData = action.payload;
        state.creatorProfileDataGetStatus =
          CREATOR_PROFILE_DATA_GET_STATUSES.IDLE;
      })
      .addCase(creatorProfileDataGet.rejected, (state, action) => {
        state.creatorProfileDataGetStatus =
          CREATOR_PROFILE_DATA_GET_STATUSES.ERROR;
      });
  },
});

export default creatorProfileSlice.reducer;

// Thunk for User Profile Data Fetching
export const creatorProfileDataGet = createAsyncThunk(
  'creatorProfileDataGet',
  async (userId, thunkAPI) => {
    try {
      const response = await API.get(`/accounts/${userId}/profile/`);
      if (response.data.code === apiErrorCodes.SUCCESS) {
        return response.data.message;
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
      }
    }
  }
);
