// Import React, React Router DOM, and Material UI
import React, {useState} from 'react';
import {Avatar, Badge, ClickAwayListener} from '@mui/material';

// Import React Icon
import {FiBell as Notifications, FiPlus as AddIcon, FiZap as CreativeInterests,} from 'react-icons/fi';

// Import the styles
import classes from './NavbarRight.module.css';

// Import the profile dropdown component
import UserProfileAvatarDropdown from './UserProfileAvatarDropdown/UserProfileAvatarDropdown';
import ContentTypeModal from '../NavbarRight/ContentTypeModal/ContentTypeModal';

// Import the notification dropdown component
import NotificationDropdown from './NotificationDropdown/NotificationDropdown';
import {handleModalType} from '../../../Store/uploadContentSlice';

// Import the notification and user profile slice from Redux
import {notificationGet} from '../../../Store/notificationSlice';
import {newNotifications} from '../../../Store/userProfileSlice';

// Import the useMediaQuery hook from Material UI
import useMediaQuery from '@mui/material/useMediaQuery';

// Import the useDispatch and useSelector hooks from React Redux
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import API from '../../../Axios/axios';
import {apiErrorCodes} from '../../../apiErrorCodes';

// Initial icon style
const iconInitialStyle = {fontSize: '4rem', color: '#9b9b9b'};

export default function NavbarRight() {
  const modalType = useSelector((state) => state.uploadContentSlice.modalType)
  const {userProfileData} = useSelector((state) => state.userProfileSlice);
  const auth = localStorage.getItem('token');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Media Queries
  const tabPort = useMediaQuery('(max-width: 600px)');

  // State variables
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isNotificationDropdownActive, setIsNotificationDropdownActive] = useState(false);
  const [isUserProfileAvatarDropdownActive, setIsUserProfileAvatarDropdownActive,] = useState(false);
  const [isContentTypeDropdownActive, setIsContentTypeDropdownActive] = useState(false);
  const [personData, setPersonData] = useState({
    userName: userProfileData.username,
    firstName: userProfileData.first_name,
    lastName: userProfileData.last_name,
    bannerImg: userProfileData.banner_pic,
    avatarImg: userProfileData.profile_pic,
    dob: userProfileData.dob,
    gender: userProfileData.gender,
    countryCode: userProfileData.country_code,
    phoneNumber: userProfileData.phone,
    address: userProfileData.address,
    city: userProfileData.city,
    state: userProfileData.state,
    country: userProfileData.country,
    zip: userProfileData.zip,
    impact: '',
  });

  const handleClickAwayNotification = async () => {
    if (isNotificationDropdownActive) {
      try {
        const response = await API.get('/notifications/dialog_open_history', {
          headers: {
            Authorization: `token ${localStorage.getItem('token')}`,
          },
        });
        if (response.data.code === apiErrorCodes.SUCCESS) {
          dispatch(newNotifications(false));
          setIsNotificationDropdownActive(false);
        }
      } catch (error) {
        if (error) {
          console.log(error);
        }
      }
    }
  };

  const handleClickAwayProfile = () => {
    setIsUserProfileAvatarDropdownActive(false);
  };

  const handleClickAwayContentType = () => {
    setIsContentTypeDropdownActive(false);
  };

  const notificationClickHandler = () => {
    if (!isNotificationDropdownActive) {
      dispatch(notificationGet()).then(() => {
        setActiveDropdown('notification');
        setIsNotificationDropdownActive(true);
      });
    }

    if (isNotificationDropdownActive) {
      handleClickAwayNotification();
    }
  };

  const profileClickHandler = () => {
    if (!isUserProfileAvatarDropdownActive) {
      setActiveDropdown('profile');
      setIsUserProfileAvatarDropdownActive(true);
    }

    if (isUserProfileAvatarDropdownActive) {
      setIsUserProfileAvatarDropdownActive(false);
    }
  };

  const contentTypeClickHandler = () => {
    if (!isContentTypeDropdownActive) {
      dispatch(handleModalType('contentType'));
      setActiveDropdown('content');
      setIsContentTypeDropdownActive(true);
    } else {
      setIsContentTypeDropdownActive(false);
    }
    // if (isContentTypeDropdownActive) {
    //   setIsContentTypeDropdownActive(false);
    // }
  };

  return (<>
    {auth ? (<div className={classes.right}>
      {/* Hide below the given section when window width is below 600px */}
      {!tabPort && (<>
        <ClickAwayListener onClickAway={handleClickAwayContentType}>
          <div className={classes.container}>
            <div className={classes.iconContainer} style={{margin: 0}}>
              <AddIcon
                className={classes.add}
                onClick={contentTypeClickHandler}
                // onClickAway={handleClickAwayContentType}
              />
              <p className='element-description '>Create</p>
            </div>

            {modalType !== null || (activeDropdown === 'content' && isContentTypeDropdownActive) ? (<ContentTypeModal
              setIsContentTypeDropdownActive={setIsContentTypeDropdownActive}
              setActiveDropdown={setActiveDropdown}
            />) : ('')}
          </div>
        </ClickAwayListener>

        <ClickAwayListener onClickAway={handleClickAwayNotification}>
          <div className={classes.iconContainer}>
            <Badge
              color="error"
              overlap="circular"
              variant="dot"
              invisible={!userProfileData.notification_status || isNotificationDropdownActive}
            >
              <Notifications className={classes.item} onClick={notificationClickHandler}/>
            </Badge>
            <p className='element-description '>Notification</p>
            {activeDropdown === 'notification' && isNotificationDropdownActive ? (
              <NotificationDropdown handleClickAwayNotification={handleClickAwayNotification}/>) : null}
          </div>
        </ClickAwayListener>

        <div
          className={classes.iconContainer}
          onClick={() => {
            navigate('/creativeinterest');
          }}
        >
          <CreativeInterests sx={iconInitialStyle} className={classes.item}/>
          <p className='element-description'>Interests</p>
        </div>
        <ClickAwayListener onClickAway={handleClickAwayProfile}>
          <div className={classes.iconContainer}>
            <Avatar
              alt="Avatar"
              className={classes.avatar}
              src={personData.avatarImg}
              sx={{width: '4rem', height: '4rem'}}
              onClick={profileClickHandler}
            ></Avatar>
            <p className='element-description'>Me</p>
            {activeDropdown === 'profile' && isUserProfileAvatarDropdownActive ? (<UserProfileAvatarDropdown
              setIsUserProfileAvatarDropdownActive={setIsUserProfileAvatarDropdownActive}
            />) : null}
          </div>
        </ClickAwayListener>
      </>)}
    </div>) : (<button
      className={classes.joinButton}
      onClick={() => navigate('/entry/signin')}
    >
      Sign In
    </button>)}
  </>);
}
