import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Stylesheet import
import classes from './BottomNavbar.module.css';

// Icon import from Feather icon
import {
  Bell as Notifications,
  Plus as AddIcon,
  Search as SearchIcon,
  Zap as CreativeInterests,
} from 'react-feather';

import ContentTypeModal from '../NavbarRight/ContentTypeModal/ContentTypeModal';

// Avatar component import frm Material UI
import Avatar from '@mui/material/Avatar';

// Click Away listener import from Material UI
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Bottom Navbar Context import
import bottomNavbarContext from '../../../Context/Navbar/bottomNavbarContext';

// Notification NotificationDropdown component import
import NotificationDropdown from '../NavbarRight/NotificationDropdown/NotificationDropdown';
import UserProfileAvatarDropdown from '../NavbarRight/UserProfileAvatarDropdown/UserProfileAvatarDropdown';

// Before SignUp
import GoToSignInModal from '../../CommonComponents/GoToSignInModal/GoToSignInModal';

// Redux dispatch to dispatch user credentials
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from '@mui/material';
import { handleModalType } from '../../../Store/uploadContentSlice';

function BottomNavbar() {
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.uploadContentSlice.modalType);
  const { userProfileData } = useSelector((state) => state.userProfileSlice);
  const auth = localStorage.getItem('token');
  const navigate = useNavigate();
  const {
    toggleIsSearchActive,
    isNotificationActive,
    toggleIsNotificationActive,
    notificationClickAwayHandler,
  } = useContext(bottomNavbarContext);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [
    isProfileNotificationDropdownActive,
    setIsProfileNotificationDropdownActive,
  ] = useState(false);
  const [isContentTypeDropdownActive, setIsContentTypeDropdownActive] =
    useState(false);
  const [goToSignInModal, setGoToSignInModal] = useState(false);
  const [personData, setPersonData] = useState({
    userName: userProfileData.username,
    firstName: userProfileData.first_name,
    lastName: userProfileData.last_name,
    bannerImg: userProfileData.banner_pic,
    avatarImg: userProfileData.profile_pic,
    dob: userProfileData.dob,
    gender: userProfileData.gender,
    countryCode: userProfileData.country_code,
    phoneNumber: userProfileData.phone,
    address: userProfileData.address,
    city: userProfileData.city,
    state: userProfileData.state,
    country: userProfileData.country,
    zip: userProfileData.zip,
    impact: '',
  });
  // const handleClickAwayNotification = () => {
  //     setIsNotificationNotificationDropdownActive(false);
  //   };
  // const [isNotificationNotificationDropdownActive, setIsNotificationNotificationDropdownActive] =
  //   useState(false);
  const handleClickAwayContentType = () => {
    setIsContentTypeDropdownActive(false);
  };

  // Handler for Clicking on search
  const searchClickHandler = () => {
    toggleIsSearchActive();
  };
  // const handleClickAwayNotification = () => {
  //   if (!isNotificationDropdownActive) {
  //     setActiveDropdown('notification');
  //     setIsNotificationDropdownActive(true);
  //   }

  //   if (isNotificationDropdownActive) {
  //     setIsNotificationDropdownActive(false);
  //   }
  // };
  const handleClickAwayAvatar = () => {
    setIsProfileNotificationDropdownActive(false);
  };
  const contentTypeClickHandler = () => {
    if (auth) {
      if (!isContentTypeDropdownActive) {
        dispatch(handleModalType('contentType'));
        setActiveDropdown('content');
        setIsContentTypeDropdownActive(true);
      }
      if (isContentTypeDropdownActive) {
        setIsContentTypeDropdownActive(false);
      }
    } else {
      setGoToSignInModal(true);
    }
  };

  const profileClickHandler = () => {
    if (auth) {
      if (!isProfileNotificationDropdownActive) {
        setActiveDropdown('profile');
        setIsProfileNotificationDropdownActive(true);
      }

      if (isProfileNotificationDropdownActive) {
        setIsProfileNotificationDropdownActive(false);
      }
    } else {
      setGoToSignInModal(true);
    }
  };

  const notificationBeforeSignIn = () => {
    if (auth) {
      toggleIsNotificationActive();
    } else {
      setGoToSignInModal(true);
    }
  };

  return (
    <div className={classes.navbar} id="bottom-navbar">
      {goToSignInModal && (
        <GoToSignInModal setGoToSignInModal={setGoToSignInModal} />
      )}
      <div
        className={classes.container}
        onClick={() => {
          if (auth) {
            navigate('/creativeinterest');
          } else {
            setGoToSignInModal(true);
          }
        }}
      >
        <CreativeInterests className={classes.item} />
      </div>

      {/* <ClickAwayListener onClickAway={() => setIsSearchActive(false)}> */}
      <SearchIcon className={classes.item} onClick={searchClickHandler} />
      {/* </ClickAwayListener> */}

      <div className={classes.container}>
        <AddIcon
          className={classes.add}
          onClick={contentTypeClickHandler}
          onClickAway={handleClickAwayContentType}
        />
        {modalType !== null | (activeDropdown === 'content' && isContentTypeDropdownActive) ? (
          <ContentTypeModal
            setIsContentTypeDropdownActive={setIsContentTypeDropdownActive}
            setActiveDropdown={setActiveDropdown}
          />
        ) : null}
      </div>
      <ClickAwayListener onClickAway={notificationClickAwayHandler}>
        <div className={classes.container}>
          {/*<Notifications className={classes.item} />*/}
          <Badge
            color="error"
            overlap="circular"
            variant="dot"
            invisible={
              !userProfileData.notification_status || isNotificationActive
            }
          >
            <Notifications
              className={classes.item}
              onClick={notificationBeforeSignIn}
            />
          </Badge>
          {isNotificationActive && <NotificationDropdown />}
        </div>
      </ClickAwayListener>

      <ClickAwayListener onClickAway={handleClickAwayAvatar}>
        <div onClick={() => profileClickHandler()}>
          <Avatar
            src={personData.avatarImg}
            className={classes.avatar}
            alt="avatar_img"
          />

          {activeDropdown === 'profile' &&
          isProfileNotificationDropdownActive ? (
            <UserProfileAvatarDropdown />
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default BottomNavbar;
