import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// images
import logo from '../../Assets/Logo/logo.svg';
import photo1 from '../../Assets/LandingPage/photo1.svg';
import photo2 from '../../Assets/LandingPage/photo2.svg';
import photo3 from '../../Assets/LandingPage/photo3.svg';
import photo4 from '../../Assets/LandingPage/photo4.png';
// import photo4 from '../../Assets/LandingPage/photo4.svg'
import { AiOutlineCompass } from 'react-icons/ai';
// css
import classes from './LandingPage.module.css';
// marquee
import Marquee from 'react-fast-marquee';

const LandingPage = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const bannerDetails = [
    {
      photo: photo1,
      title: 'Showcase your awesome builds to the world.',
      color: '#D61355',
    },
    {
      photo: photo2,
      title: 'Bring creativity to everyday life. ',
      color: '#3500D3',
    },
    {
      photo: photo3,
      title: 'Get inspired from unlimited creations.',
      color: '#E2A311',
    },
    {
      photo: photo4,
      title: 'Personal diary of all your cool projects.',
      color: '#FC4444',
    },
  ];
  useEffect(() => {
    setTimeout(() => {
      let tempIndex = (index + 1) % bannerDetails.length;
      setIndex(tempIndex);
    }, 4000);
  }, [index]);
  const marqueeData = [
    [
      { id: 12, name: 'Biotechnology' },
      { id: 11, name: 'Biology' },
      {
        id: 10,
        name: 'Automation',
      },
      { id: 13, name: 'Cad' },
      { id: 6, name: 'Aquaponics' },
      { id: 3, name: '3D Printing' },
      {
        id: 7,
        name: 'Arduino',
      },
      { id: 9, name: 'Artificial Intelligence' },
      { id: 15, name: 'Circuitpython' },
      {
        id: 8,
        name: 'Arduino Bots',
      },
      { id: 4, name: 'Alternative Energy Vehicles' },
      { id: 1, name: '3D Designing' },
      {
        id: 5,
        name: 'Animatronics',
      },
      { id: 2, name: '3D Modeling' },
      { id: 14, name: 'Casting' },
    ],
    [
      {
        id: 25,
        name: 'Green Innovations',
      },
      { id: 28, name: 'Kinetic Art' },
      { id: 282, name: 'Frugal Innovation' },
      {
        id: 212,
        name: 'Creative Engineering',
      },
      { id: 19, name: 'Coding' },
      { id: 27, name: 'Kinematics' },
      { id: 30, name: 'Lego Robotics' },
      {
        id: 20,
        name: 'Computer Vision',
      },
      { id: 22, name: 'Electrical Engineering' },
      { id: 17, name: 'Clean Energy' },
      {
        id: 16,
        name: 'Circuits',
      },
      { id: 18, name: 'Cnc Machining' },
      { id: 23, name: 'Electronics' },
      {
        id: 29,
        name: 'Lego Machines',
      },
      { id: 26, name: 'Internet Of Things' },
    ],
    [
      { id: 44, name: 'Recycling' },
      {
        id: 32,
        name: 'Makey Makey',
      },
      { id: 41, name: 'Pneumatics' },
      { id: 36, name: 'Microbit' },
      {
        id: 37,
        name: 'Microcontrollers',
      },
      { id: 40, name: 'Physical Computing' },
      { id: 42, name: 'Quadrotor' },
      {
        id: 34,
        name: 'Mechanisms',
      },
      { id: 33, name: 'Mechanical Engineering' },
      { id: 43, name: 'Raspberry Pi' },
      {
        id: 31,
        name: 'Machining',
      },
      { id: 39, name: 'Pcb' },
      { id: 35, name: 'Mechatronics' },
      { id: 45, name: 'Robotics' },
      {
        id: 38,
        name: 'Origami Engineering',
      },
    ],
    [
      { id: 47, name: 'Science Toys' },
      { id: 53, name: 'Tinkercad' },
      {
        id: 55,
        name: 'Woodworking',
      },
      { id: 50, name: 'Solar Energy' },
      { id: 51, name: 'Soldering' },
      { id: 54, name: 'Tinkering' },
      {
        id: 48,
        name: 'Scratch Programming',
      },
      { id: 52, name: 'Sustainable Development' },
      { id: 46, name: 'Rural Innovations' },
      {
        id: 49,
        name: 'Smart Home',
      },
    ],
  ];

  const Tag = ({ title }) => {
    return <div className={classes.tagBox}>{title}</div>;
  };
  const Title = ({ text }) => {
    return <div className={classes.bannerTitle}>{text}</div>;
  };
  return (
    <div className={classes.landingPage}>
      <Helmet>
        <title>Home | Srijcon</title>
        <meta
          name="description"
          content="Srijcon connects creative and Innovative minds to come together and share inspiring creations and ideas to impact the world."
        />
      </Helmet>
      {/* Navbar Starts Here */}
      <div className={classes.navbar}>
        <div className={classes.logoContainer}>
          <img
            draggable="false"
            src={logo}
            alt="Srijcon Logo"
            className={classes.logo}
          />
          <p className={classes.logoText}>Srijcon </p>
        </div>
        <div>
          <button
            className={classes.signInButton}
            onClick={() => navigate('/entry/signin')}
          >
            Sign In
          </button>
        </div>
      </div>
      {/* Navbar Ends Here */}
      <div
        className={classes.bannerSection}
        style={{
          backgroundImage: `url(${bannerDetails[index]['photo']})`,
          backgroundColor: bannerDetails[index]['color'],
        }}
      >
        <div className={classes.bannerText}>
          <div className={classes.bannerHeading}>
            Connecting the innovative and creative minds
          </div>
          <div>
            {bannerDetails.map((data, key) => {
              return <>{key === index && <Title text={data.title} />}</>;
            })}
            <button
              className={`${classes.btn} ${classes.sheen}`}
              onClick={() => navigate('/entry/signup')}
            >
              Connect Now
            </button>
          </div>
        </div>
      </div>

      <div className={classes.marqueeSection}>
        <div className={classes.secondHeading}>
          <p style={{ textAlign: 'center' }}>
            Your creative interest, You name it,
          </p>
          <span>We have it!</span>
        </div>
        <div className={classes.marquee}>
          {marqueeData.map((marquee, index) => {
            return (
              <Marquee
                direction={index % 2 === 0 ? 'left' : 'right'}
                speed={30}
              >
                {marquee.map((title, index) => {
                  return <Tag title={title.name} key={index} />;
                })}
              </Marquee>
            );
          })}
        </div>
        <button
          className={classes.exploreButton}
          onClick={() => navigate('/entry/signin')}
        >
          Explore &nbsp; <AiOutlineCompass />
        </button>
      </div>
      <div className={classes.footer}>
        <div className={classes.copyright}>
          <span>&copy;</span>
          Srijcon Innovations Pvt. Ltd. | All Rights Reserved
        </div>
        <a className={classes.privacyPolicyLink} href="/termsandconditions" target="_blank">
          Terms of Use & Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
