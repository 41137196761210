import React, { useState } from 'react';
import { Box, Grow, Modal } from '@mui/material';

import { FiX } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import style from './UserAlreadyExistModal.module.css';

export default function UserAlreadyExistModal(props) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    props.setUserAlreadyExistModal(false);
    navigate('/entry/signup');
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div>
          <Grow in={true}>
            <Box className={style.userAlreadyExistModal}>
              <FiX className="cross-button" onClick={handleClose} />
              <Box className={style.userAlreadyExistModalBox}>
                <p className={style.userAlreadyExistText}>
                  User already exists.
                </p>
                <p className={style.signInText}>
                  Please go to{' '}
                  <Link
                    style={{ color: '#b6193a', textDecoration: 'none' }}
                    to="/entry/signin"
                    onClick={() => {
                      setOpen(false);
                      props.setUserAlreadyExistModal(false);
                    }}
                  >
                    SignIn.
                  </Link>
                </p>
              </Box>
            </Box>
          </Grow>
        </div>
      </Modal>
    </>
  );
}
