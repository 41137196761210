import { createTheme } from '@mui/material';

const customColors = {
  // Shades of Red
  redPrimary: '#d11414',
  redLight: '#e90000',
  redDark: '#be2727',

  // Shades of Grey Light
  greyLight1: '#eee',
  greyLight2: '#ddd',
  greyLight3: '#cbcdda',

  // Shades of Grey Medium
  greyMedium1: '#b9b9b9',
  greyMedium2: '#a3a3a3',
  greyMedium3: '#6e6e6e',

  // Shades of Grey Dark
  greyDark1: '#333',
  greyDark2: '#414141',
  greyDark3: '#888',
};

export const theme = createTheme({
  palette: {
    primary: {
      main: customColors.redPrimary,
      dark: customColors.redDark,
      light: customColors.redLight,
      contrastText: '#fff',
    },
    secondary: {
      main: '#333',
    },
    lightGrey: {
      light: customColors.greyLight1,
      main: customColors.greyLight2,
      dark: customColors.greyLight3,
    },
    mediumGrey: {
      light: customColors.greyMedium1,
      main: customColors.greyMedium2,
      dark: customColors.greyMedium3,
    },
    darkGrey: {
      light: customColors.greyDark1,
      main: customColors.greyDark2,
      dark: customColors.greyDark3,
    },
  },
  text: {
    primary: '#fff',
    secondary: '#000',
  },
});
