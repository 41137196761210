import React, { useRef, useState } from 'react';

import { countCharacters } from '../../../HelperFunctions/countCharacters';
// import UnsupportedFileModal from '../../Navbar/NavbarRight/ContentTypeModal/UploadContentModal/UnsupportedFileModal/UnsupportedFileModal';

// importing useNavigate for navigation
import { useNavigate } from 'react-router-dom';

// React hook form library imported for form validation
import { useForm } from 'react-hook-form';

// Stylesheet import
import classes from './UserDetails.module.css';

// TextField import from Material UI
import { TextField } from '@mui/material';

// styled-components imported
import styled from 'styled-components';

// imports for icons
import { TiCamera } from 'react-icons/ti';

// importing base URL for API requests
import API from '../../../Axios/axios';

// import user's profile data from userProfileSlice
import {
  newNotifications,
  userProfileDataGet,
} from '../../../Store/userProfileSlice';

// Redux dispatch to dispatch user credentials
import { useDispatch } from 'react-redux';

// import default image for avatar
import defaultAvatarImage from '../../../Assets/Avatar/avatar2.webp';

import { Helmet } from 'react-helmet-async';

// import cropper
import ModalCropper from '../../ImageCropper/ModalCropper';
import { apiErrorCodes } from '../../../apiErrorCodes';
import { isValidImage } from '../../../HelperFunctions/checkValidity/isValidImage';
import UnsupportedFileModal from '../../Navbar/NavbarRight/ContentTypeModal/UploadContentModal/ErrorModals/UnsupportedFileModal/UnsupportedFileModal';
import createWebSocket from '../../../WebSocket';
import { liveNotificationAdd } from '../../../Store/notificationSlice';

const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

export default function UserDetails() {
  const dispatch = useDispatch();
  // Stuff related to the react-hook-form
  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const inputAvatarFileRef = useRef(null);
  const [avatarImageBlob, setAvatarImageBlob] = useState(defaultAvatarImage);
  const [avatarImageFile, setAvatarImageFile] = useState(null);
  const [avatarImageError, setAvatarImageError] = useState('');
  const [imgLoad, setImgLoad] = useState(false);
  const navigate = useNavigate();
  const inputFileRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImg, setModalImg] = useState(null);
  const [extension, setExtension] = useState(null);
  const [unsupportedFile, setUnsupportedFile] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [unsupportedFileModal, setUnsupportedFileModal] = useState(false);

  const onSubmit = async (event) => {
    setAvatarImageError(false);
    setButtonDisabled(true);
    const formData = new FormData();
    formData.append('username', event.userName);
    formData.append('first_name', event.firstName);
    formData.append('last_name', event.lastName);
    if (avatarImageFile != null) {
      formData.append('profile_pic', avatarImageFile);
    }

    try {
      const response = await API.patch('/accounts/myprofile/', formData, {
        headers: {
          Authorization: `token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.code === apiErrorCodes.SUCCESS) {
        setButtonDisabled(false);
        navigate('/entry/creativeinterest');
        dispatch(userProfileDataGet()).then((response) => {
          const socket = createWebSocket(response.payload.id);

          socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message?.notification?.code === 'new__notification') {
              dispatch(newNotifications(true));
              dispatch(liveNotificationAdd(message.notification.details));
            }
          };

          return () => {
            socket.close();
          };
        });
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
        error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
        error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
        error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
      ) {
        localStorage.removeItem('token');
        navigate('/entry/signin');
      }
      if (error.response.data.code === apiErrorCodes.USERNAME_ALREADY_TAKEN) {
        setButtonDisabled(false);
        setError('userName', {
          type: 'manual',
          message: 'Username is already taken.',
        });
      }
      if (error.response.data.message.first_name) {
        setButtonDisabled(false);
        setError('firstName', {
          type: 'manual',
          message: 'Required field.',
        });
      }
      if (error.response.data.message.last_name) {
        setButtonDisabled(false);
        setError('lastName', {
          type: 'manual',
          message: 'Required field.',
        });
      }
    }
  };
  const handleAvatarImgChange = (event) => {
    const newAvatarImage = event.target?.files?.[0];
    event.target.value = null;
    const validity = isValidImage(newAvatarImage);
    if (validity === null) {
      setImgLoad(true);
      setModalImg(URL.createObjectURL(newAvatarImage));
      setExtension(newAvatarImage.name.split(".").pop());
      setIsModalOpen(true);  
    }
    else {
      setUnsupportedFile(true);
      setErrorType(validity);
    }
    
    // const maxFileSizeLimit = 1024;
    // const maxFileSizeLimit = 3072;
    // if (newAvatarImage.size / 1024 > maxFileSizeLimit) {
    //   setImgSizeError(true);
    //   return;
    // }
    // if (newAvatarImage) {
    //   setImgSizeError(false);
    // }
  };

  const handleModalImageChange = (blob, file) => {
    setIsModalOpen(false);
    setAvatarImageBlob(blob);
    setAvatarImageFile(file);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>User Details | Srijcon</title>
        <link rel="canonical" href="/entry/userdetails" />
        <meta
          name="description"
          // content="Srijcon - Connect with innovators, inventors, makers, creators and share your projects, innovations, creative works or STEM creations with the world."
          content="Srijcon connects creative and Innovative minds to come together and share inspiring creations and ideas to impact the world."
        />
      </Helmet>
      {unsupportedFileModal && (
        <UnsupportedFileModal
          setUnsupportedFileModal={setUnsupportedFileModal}
          errorType={errorType}
        />
      )}
      {isModalOpen && (
        <ModalCropper
          image={modalImg}
          onClose={handleClose}
          handleApply={handleModalImageChange}
          aspect={1}
          extension = {extension}
          shape="round"
        />
      )}
      <div className={classes.screenContainer}>
        <div className={classes.mainDivContainer}>
          <div className={classes.divLeft}>
            <div className={classes.logoDiv}>
              <h1 className={classes.logoHeading}>Srijcon</h1>
            </div>
            <div className={classes.descriptionDiv}>
              <h1 className={classes.descriptionHeading}>
                Connect. Create. <br /> Impact.
              </h1>
              <p className={classes.descriptionText}>
                We connect Makers and Innovative minds to come together and
                share inspiring creations and ideas to impact the world.
              </p>
            </div>
          </div>
          <div className={classes.divRight}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className={classes.rightContainer}>
                <div className={classes.rightContainerDesc}>
                  <h2 className={classes.heading}> User Details</h2>
                </div>
                {isModalOpen && (
                  <ModalCropper
                    image={modalImg}
                    onClose={handleClose}
                    handleApply={handleModalImageChange}
                    aspect={1}
                    extension={extension}
                    shape="round"
                  />
                )}
                {!imgLoad ? (
                  <div className={classes.rightAvtContainer}>
                    {/*<div className={classes.cameraIconAsAvt} onClick={() => inputFileRef.current.click()}>*/}
                    {/*  <TiCamera className={classes.cameraIcon} />*/}
                    {/*</div>*/}
                    <div>
                      <div className={classes.editImgAvt1}>
                        <img
                          draggable="false"
                          src={avatarImageBlob}
                          alt="AvatarImage"
                        />
                      </div>
                      <div className={classes.cameraIcon2}>
                        <TiCamera
                          className={classes.fillCameraIcon}
                          onClick={() => inputAvatarFileRef.current.click()}
                        />
                        <input
                          ref={inputAvatarFileRef}
                          hidden
                          accept=".jpg, .jpeg, .png"
                          id="avatar-image-upload"
                          type="file"
                          onChange={handleAvatarImgChange}
                        />
                      </div>
                    </div>

                    <input
                      ref={inputFileRef}
                      hidden
                      accept=".jpg, .jpeg, .png"
                      id="avatar-image-upload"
                      type="file"
                      onChange={handleAvatarImgChange}
                    />
                  </div>
                ) : (
                  <div className={classes.rightAvtContainer}>
                    <div className={classes.rightAvt}>
                      <img
                        draggable="false"
                        src={avatarImageBlob}
                        alt="AvatarImage"
                      />
                    </div>
                    <TiCamera
                      className={classes.cameraIconAvt}
                      onClick={() => inputFileRef.current.click()}
                    />
                    <input
                      ref={inputFileRef}
                      hidden
                      accept=".jpg, .jpeg, .png"
                      id="avatar-image-upload"
                      type="file"
                      onChange={handleAvatarImgChange}
                    />
                  </div>
                )}
                {avatarImageError ? (
                  <div>
                    <p style={{ fontSize: '1.4rem', color: 'red' }}>
                      Please Upload your profile picture.
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className={classes.formContainer}>
                <div className={classes.formFieldsContainer}>
                  <div className={classes.fieldsOnly}>
                    <div className={classes.gridMake}>
                      <StyledTextField
                        className={classes.textCheckOne}
                        name="firstName"
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        InputProps={{ style: { fontSize: '1.4rem' } }}
                        inputProps={{
                          maxLength: 40,
                          style: {
                            textTransform: 'uppercase',
                            pattern: '[a-z]',
                          },
                        }}
                        InputLabelProps={{
                          style: { fontSize: '1.4rem', color: '#6e6e6e' },
                        }}
                        {...register('firstName', {
                          required: 'Required field',
                          onChange: () => {
                            setValue(
                              'firstName',
                              getValues('firstName')
                                .replace(/[^a-zA-Z ]/gm, '')
                                .replace(/\s+/g, ' ')
                                .replace(/\w\S*/g, function (txt) {
                                  return (
                                    txt.charAt(0).toUpperCase() +
                                    txt.substr(1).toLowerCase()
                                  );
                                })
                            );
                          },
                        })}
                        error={!!errors?.firstName}
                        helperText={
                          errors?.firstName ? errors.firstName.message : null
                        }
                      />

                      <StyledTextField
                        className={classes.textCheckTwo}
                        name="lastName"
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        InputProps={{ style: { fontSize: '1.4rem' } }}
                        inputProps={{
                          maxLength: 40,
                          style: { textTransform: 'uppercase' },
                        }}
                        InputLabelProps={{
                          style: { fontSize: '1.4rem', color: '#6e6e6e' },
                        }}
                        {...register('lastName', {
                          required: 'Required field',
                          onChange: () => {
                            setValue(
                              'lastName',
                              getValues('lastName')
                                .replace(/[^a-zA-Z ]/gm, '')
                                .replace(/\s+/g, ' ')
                                .replace(/\w\S*/g, function (txt) {
                                  return (
                                    txt.charAt(0).toUpperCase() +
                                    txt.substr(1).toLowerCase()
                                  );
                                })
                            );
                          },
                        })}
                        error={!!errors?.lastName}
                        helperText={
                          errors?.lastName ? errors.lastName.message : null
                        }
                      />
                    </div>
                    <div className={classes.gridMake2}>
                      <StyledTextField
                        className={classes.textCheckThree}
                        name="userName"
                        id="outlined-basic"
                        label="Create Username"
                        variant="outlined"
                        InputProps={{ style: { fontSize: '1.4rem' } }}
                        inputProps={{ style: { textTransform: 'lowercase' } }}
                        InputLabelProps={{
                          style: { fontSize: '1.4rem', color: '#6e6e6e' },
                        }}
                        {...register('userName', {
                          required: 'Required field',
                          pattern: {
                            value:
                              /^(?=[a-zA-Z0-9._]{4,30}$)(?!.*[._]{2})[^._].*[^._]$/,
                            message:
                              'Character limit: 4-30, a-z, 0-9, dot(.), underscore(_).',
                          },
                          onChange: () => {
                            setValue(
                              'userName',
                              getValues('userName')
                                .replace(/[^a-zA-Z0-9._]/gm, '')
                                .toLowerCase()
                            );
                          },
                        })}
                        error={!!errors?.userName}
                        helperText={
                          errors?.userName ? errors.userName.message : null
                        }
                      />
                    </div>
                  </div>
                </div>
                {!buttonDisabled ? (
                  <button type="submit" className={classes.button}>
                    Save
                  </button>
                ) : (
                  <button type="button" className={classes.buttonDisabled}>
                    Save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
