// UseState Hook import
import React, { useState, useContext, useEffect } from 'react';

// Stylesheet import
import classes from './Navbar.module.css';

// Navbar Left and Right Component Import
import NavbarLeft from './NavbarLeft/NavbarLeft';
import NavbarRight from './NavbarRight/NavbarRight';

// Search Input component import
import SearchInput from './NavbarLeft/SearchInput/SearchInput';

// Bottom Navbar Context Import
import bottomNavbarContext from '../../Context/Navbar/bottomNavbarContext';

// Click Away listener import from Material UI
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useViewport } from "../../CustomHooks/useViewport";

// Thunk
import {  TVPagePostsGet } from '../../Store/TVPagePostsSlice';

// Redux dispatch to dispatch user credentials
import { useDispatch } from 'react-redux';

const Navbar = function () {
  const dispatch = useDispatch();

  const { width } = useViewport();
  const breakpoint = 600;


  // Destructing Active Search for Bottom Navbar Search Click
  const { isSearchActive, toggleIsSearchActive, setIsSearchActive } = useContext(bottomNavbarContext);

  // Setting Active Dropdown
  const [activeDropdown, setActiveDropdown] = useState(null);

  // State for clickawaylistner
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = useState('');

  // Functionn for clickawaylistner
  const handleClickAway = () => {
    setOpen(false);
    setActiveDropdown(null);
  };

  // Function for Click on Navbar Favorite icon
  const favoriteDropdownHandler = () => {
    if (!activeDropdown || activeDropdown !== 'favorites') {
      setActiveDropdown('favorites');
      setOpen(true);
    }
    if (activeDropdown === 'favorites') setActiveDropdown(null);
  };

  // Function for Click on Navbar Notification icon
  const notificationDropdownHandler = () => {
    if (!activeDropdown || activeDropdown !== 'notification') {
      setActiveDropdown('notification');
      setOpen(true);
    }
    if (activeDropdown === 'notification') setActiveDropdown(null);
  };

  // Function for Click on Navbar Profile icon
  const profileDropdownHandler = () => {
    if (!activeDropdown || activeDropdown !== 'profile')
      setActiveDropdown('profile');
    if (activeDropdown === 'profile') setActiveDropdown(null);
  };
  // Function for Click on Navbar Profile icon
  const onChangeHandler = (e) => {
    setValue(e.target.value);
    if (e.target.value === null || e.target.value === "") {
      dispatch(TVPagePostsGet());
    }
  };

  useEffect(() => {
    if (width >= breakpoint) {
      setIsSearchActive(false)
    }
  }, [width])

  return (
    <div className={classes.navbar} id="top-navbar">
      {/* Display left and right navbar if isSearchActive is false */}
      {!isSearchActive && (
        <>
          <NavbarLeft
            activeDropdown={activeDropdown}
            favoriteDropdownHandler={favoriteDropdownHandler}
            notificationDropdownHandler={notificationDropdownHandler}
            handleClickAway={handleClickAway}
            open={open}
          />
          <NavbarRight
            activeDropdown={activeDropdown}
            profileDropdownHandler={profileDropdownHandler}
          />
        </>
      )}
      {/* Display search input when isSearchActive is true on click on Search button on bottom Navigation */}
      {isSearchActive && (
        <ClickAwayListener onClickAway={() => setIsSearchActive(false)}>
          <div style={{ width: '100%', padding: '0.15rem' }}>
            {/* <Modal open={true} onClose={toggleIsSearchActive}> */}

            {/* <ClickAwayListener onClickAway={toggleIsSearchActive}> */}
            <SearchInput
              data={{ type: 'text' }}
              showClose={true}
              width="100%"
              closeInput={toggleIsSearchActive}
              placeholder='Search Srijcon'
              value={value}
              onChange={(e) => onChangeHandler(e)}
            />
            {/* </Modal> */}
          </div>
        </ClickAwayListener>
      )
      }
    </div>
  );
};

export default Navbar;
