import React, { useState } from 'react';

// Stylesheets imported
import classes from './UpdatePassword.module.css';

// MUI components imported
import {
  Box,
  Grow,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from '@mui/material';

// Styled component imported
import styled from 'styled-components';

// Icons imported
import { FiLock, FiUnlock, FiX } from 'react-icons/fi';

// react-hook-form imported for form validation
import { useForm } from 'react-hook-form';

// API base addresses imported
import API from '../../../../Axios/axios';

// Components
import SuccessMessageModal from './SuccessMessageModal/SuccessMessageModal';
import { apiErrorCodes } from '../../../../apiErrorCodes';
import { flushSync } from 'react-dom';

// MUI text-field with styled components for customization
const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

export default function UpdatePasswordModal(props) {
  // Boolean value for opening current modal
  const [open, setOpen] = useState(true);

  // boolean value to disable and enable buttons
  const [buttonDisabled, setButtonDisabled] = useState(false);

  //  Boolean value to trigger the icon for showing and hiding the new password
  const [showNewPassword, setShowNewPassword] = useState(false);

  // Boolean value for opening and closing the success message modal
  const [successMessageModal, setSuccessMessageModal] = useState(false);

  // react-hook-form stuff
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  // function to set the boolean value to show and hide the new password
  const handleClickShowPassword = () => setShowNewPassword(!showNewPassword);

  // form submission function
  const onSubmit = async (data) => {
    setButtonDisabled(true);

    const formData = new FormData();
    formData.append('email', props.email);
    formData.append('password', data.newPassword);
    try {
      const response = await API.post('/accounts/resetpassword', formData);
      if (response.data.code === apiErrorCodes.SUCCESS) {
        // this will enable the button and open the acknowledgment modal and close the current modal
        flushSync(() => {
          setSuccessMessageModal(true);
          setTimeout(() => {
            setButtonDisabled(false);
            props.setOpenModal(false);
          }, 2000);
        });
      }
    } catch (error) {
      if (
        error.response.data.code === apiErrorCodes.INVALID_PASSWORD ||
        error.response.data.code === apiErrorCodes.PASSWORD_IS_REQUIRED ||
        error.response.data.code === apiErrorCodes.USERNAME_IS_REQUIRED ||
        error.response.data.code ===
          apiErrorCodes.EMAIL_AND_PASSWORD_ARE_REQUIRED
      )
        setButtonDisabled(false);
    }
  };

  // function for closing the current modal
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {successMessageModal && (
        <SuccessMessageModal
          setSuccessMessageModal={setSuccessMessageModal}
          handleClose={handleClose}
        />
      )}
      {successMessageModal ? (
        ''
      ) : (
        <Modal open={open}>
          <div>
            <Grow in={true}>
              <Box className={classes.updatePasswordModal}>
                <FiX
                  className="cross-button"
                  onClick={() => {
                    props.setUpdatePasswordModal(false);
                    props.setOpenModal(false);
                  }}
                />
                <Box className={classes.updatePasswordModalHeader}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                    className={classes.updatePasswordModalForm}
                  >
                    <StyledTextField
                      label="New password"
                      variant="outlined"
                      type={showNewPassword ? 'text' : 'password'} // <-- This is where the magic happens
                      sx={{ width: '90%' }}
                      InputLabelProps={{
                        style: { fontSize: '1.4rem', color: '#6e6e6e' },
                      }}
                      InputProps={{
                        style: { fontSize: '1.4rem', fontWeight: 400 },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showNewPassword ? <FiUnlock /> : <FiLock />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...register('newPassword', {
                        required: 'Required field',
                        pattern: {
                          value:
                            // Must have alphabets, Number and minimum 8 characters, but it can also contain special characters.
                            /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9!#$%&'*+-/=?^_`{|@.]{8,}$/,

                          // Must have Special Character, UpperCase, LowerCase, Number and minimum 8 characters.
                          // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          message:
                            'Must be (a - z), (0 - 9) and minimum 8 characters.',
                          // 'Must have Special Character, UpperCase, LowerCase, Number and minimum 8 characters.'
                        },
                      })}
                      error={!!errors?.newPassword}
                      helperText={
                        errors?.newPassword ? errors.newPassword.message : null
                      }
                    />
                    {!buttonDisabled ? (
                      <button
                        type="submit"
                        className={classes.updateBtnEnabled}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={classes.updateBtnDisabled}
                      >
                        Update
                      </button>
                    )}
                  </form>
                </Box>
              </Box>
            </Grow>
          </div>
        </Modal>
      )}
    </>
  );
}
