import React, {useContext, useEffect, useState} from 'react';
// Modal
import Modal from '@mui/material/Modal';

//import icons
import {FiArrowLeft, FiX} from 'react-icons/fi';
import {ImAttachment} from 'react-icons/im';

//import css
import classes from './DetailsModal.module.css';

// Error modals
import CloseWarningModal from '../../ErrorModals/CloseWarningModal/CloseWarningModal';

// redux reducers
import {handleDetails, handleModalType,} from '../../../../../../../Store/uploadContentSlice';
import {useDispatch, useSelector} from 'react-redux';

// Editor
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';

// Loader
import CircularProgress from '@mui/material/CircularProgress';

// Dropdown
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Context
import UploadContentContext from '../../../../../../../Context/UploadContent/uploadContentContext';

// cropping
import getCroppedImg from '../../../../../../ImageCropper/cropImage';
import {apiErrorCodes} from '../../../../../../../apiErrorCodes';
import API from '../../../../../../../Axios/axios';
import {useNavigate} from "react-router-dom";
import AcknowledgementModal from "../../Utilities/AcknowledgementModal/AcknowledgementModal";
import UploadingProgressModal from "../../Utilities/UploadingProgressModal/UploadingProgressModal";
import { Oval } from  'react-loader-spinner'
const DetailsModal = (props) => {
  const token = localStorage.getItem('token');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux Store
  const uploadContentData = useSelector((state) => state.uploadContentSlice);
  const videoBlobs = useSelector(
    (state) => state.uploadContentSlice.videoBlobs
  );
  const mediaBlobs = useSelector((state) => state.uploadContentSlice.mediaBlob);
  const mediaState = useSelector((state) => state.uploadContentSlice.mediaState);
  const extension = useSelector((state) => state.uploadContentSlice.mediaExtension);

  // Context
  const {attachments, videoBlobToFile} = useContext(UploadContentContext);

  // Modal Open/Close States
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 650);
  const [closeWarning, setCloseWarning] = useState(false);
  const [postDescription, setPostDescription] = useState('');
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disablePublish, setDisablePublish] = useState(false);
  const [descriptionLength, setDescriptionLength] = useState(0);
  // Boolean values to display the acknowledgement modal after uploading the media
  const [acknowledgementModal, setAcknowledgementModal] = useState(false);
  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState()

  useEffect(() => {
    try {
      setLoading(true);
      const promise = new Promise((resolve, reject) => {
        if (uploadContentData !== undefined) {
          setPostDescription(uploadContentData.postDescription);
          setCategories(uploadContentData.categories);
          setDescriptionLength(uploadContentData.postDescription.replace(/(<([^>]+)>)/gi, '').replace(/(&nbsp;)/gi, '').length);
        }
        resolve('SUCCESS');
      });
      promise.then((response) => {
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (categories.length >= 3) {
      setDisablePublish(false);
    } else {
      setDisablePublish(true);
    }
  }, [categories]);

  const handleModalClose = () => {
    setCloseWarning(true);
  };
  const handleBack = () => {
    try {
      dispatch(handleModalType('media'));
      let tempDetails = {
        postDescription: postDescription,
        categories: categories,
      };
      dispatch(handleDetails(JSON.stringify(tempDetails)));
    } catch (e) {
      console.log('error in sending data to redux', e);
    }
  };
  const handlePublish = async () => {
    setIsLoading(true);
    try {
      setDisablePublish(true);
      let tempDetails = {postDescription: postDescription, categories: categories};
      dispatch(handleDetails(JSON.stringify(tempDetails)));

      // cropping blobs
      let tempProcessedBlob = [];
      let tempExtension = {};
      let tempDict = {};
      await Promise.all(
        mediaBlobs.map(async (blob) => {
          if (videoBlobs.indexOf(blob) === -1) {
            const ext = extension[blob];
            tempDict[blob] = await getCroppedImg(
              blob,
              mediaState[blob]['croppedAreaPixels'],
              mediaState[blob]['rotation'],
              ext
            );
          }
        })
      );

      mediaBlobs.some((blob) => {
        if (videoBlobs.indexOf(blob) === -1) {
          tempProcessedBlob.push(tempDict[blob]);
          tempExtension[tempDict[blob]] = extension[blob];
        } else {
          tempExtension[blob] = extension[blob];
          tempProcessedBlob.push(blob);
        }
      });

      // converting cropped blobs to file
      let promises = [];
      Array.from(tempProcessedBlob).forEach((mediaBlob) => {
        console.log(mediaBlob);
        if (videoBlobs.indexOf(mediaBlob) === -1) {
          const promise = new Promise((resolve, reject) => {
            fetch(mediaBlob)
              .then((response) => response.blob())
              .then((blob) => {
                const filename = 'processed_File';
                const fileType = tempExtension[mediaBlob];
                const file = new File([blob], filename, {type: fileType});
                resolve(file);
              });
          });
          promises.push(promise);
        } else {
          promises.push(videoBlobToFile[mediaBlob]);
        }
      });
      await Promise.all(promises).then((response) => {
        // Data being sent to server
        const dataSendToServer = {
          postTitle: uploadContentData.project,
          // postId: uploadContentData.project.id,.
          mediaFiles: response,
          postDescription: postDescription,
          categories: categories,
          attachments: attachments,
          aspectRatio: uploadContentData.aspectRatio,
          contentType: uploadContentData.contentType,
        };
        setData(dataSendToServer);
        setDisablePublish(false);
        setOpenProgressModal(true);
        setIsLoading(false);
      });
    } catch (e) {
      console.log('error in sending data to redux', e);
    }
  };

  const handleDescription = (e) => {
    let promise = new Promise((resolve, reject) => {
      let newStr = e.target.value.replace(/(<([^>]+)>)/gi, '');
      newStr = newStr.replace(/(&nbsp;)/gi, '');
      const len = newStr.length;
      resolve(len);
    });
    promise.then((response) => {
      if (response <= 1000) {
        setPostDescription(e.target.value);
        setDescriptionLength(response);
      }
    });
  };

  const handleAttachFiles = (e) => {
    try {
      let tempDetails = {
        postDescription: postDescription,
        categories: categories,
      };
      dispatch(handleDetails(JSON.stringify(tempDetails)));
      dispatch(handleModalType('attachments'));
    } catch (e) {
      console.log('error in sending data to redux', e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await API.get('/core/interests/', {
          headers: {
            Authorization: `token ${localStorage.getItem('token')}`,
          },
        });
        if (response.data.code === apiErrorCodes.SUCCESS) {
          setAllCategories(response.data.message);
        }
      } catch (error) {
        if (
          error.response.data.code === apiErrorCodes.AUTHENTICATION_FAILED ||
          error.response.data.code === apiErrorCodes.NOT_AUTHENTICATED ||
          error.response.data.code === apiErrorCodes.INVALID_TOKEN ||
          error.response.data.code === apiErrorCodes.ACCOUNT_NOT_EXIST
        ) {
          localStorage.removeItem('token');
          navigate('/entry/signin');
        }
      }
    })();
  }, []);

  return (
    <>
      {closeWarning && (
        <CloseWarningModal
          setCloseWarning={setCloseWarning}
          onClose={props.onClose}
        />
      )}
      {openProgressModal && (
        <UploadingProgressModal
          data={data}
          onClose={props.onClose}
          setOpenProgressModal={setOpenProgressModal}
        />
      )}
      {acknowledgementModal && (
        <AcknowledgementModal
          message={'Content Published Successfully.'}
          setAcknowledgementModal={setAcknowledgementModal}
        />
      )}
      <Modal open={true} className={classes.modal}>
        {/*<Grow in={true}>*/}
        <div className={classes.wholeModal}>
          {isLoading &&
            <Oval
              height={60}
              width={60}
              color="#d11414"
              wrapperStyle={{
                padding: '0',
                position: 'absolute',
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'blur(8px)',
                zIndex: '1',
              }}
              ariaLabel='oval-loading'
              secondaryColor="#d11414"
              strokeWidth={2}
              strokeWidthSecondary={2} />
          }
          <div className={classes.modalHeading}>
            <FiArrowLeft
              onClick={loading ? () => {
              } : () => handleBack()}
              style={{margin: 0}}
              className="cross-button"
            />
            <p className="component-heading">POST DESCRIPTION</p>
            <FiX
              style={{margin: 0}}
              className="cross-button"
              onClick={handleModalClose}
            />
          </div>
          <div className={classes.innerModal}>
            {loading ? (
              <div className={classes.loader}>
                <CircularProgress/>
              </div>
            ) : (
              <>
                <div className={classes.insideEditModal}>
                  <EditorProvider>
                    <Editor
                      value={postDescription}
                      onChange={handleDescription}
                      containerProps={{className: classes.descriptionBox}}
                      placeholder={`How it's made/ How it works?`}
                      style={{
                        minHeight: '5rem',
                        maxHeight: 'fit-content',
                      }}
                    >

                    </Editor>
                    <Toolbar
                      style={{
                        background: 'white',
                        border: 'none',
                        height: 'fit-content',
                        width: '100%',
                      }}
                    >
                      <BtnBold/>
                      <BtnItalic/>
                      <BtnUnderline/>
                      <BtnNumberedList/>
                      <BtnBulletList/>
                    </Toolbar>
                  </EditorProvider>

                </div>
                <div className={classes.wordCount}>
                  {descriptionLength}/1000
                </div>
                <Autocomplete
                  disabled={categories.length >= 20}
                  disableClearable
                  multiple
                  disableCloseOnSelect
                  limitTags={2}
                  options={allCategories}
                  getOptionLabel={(option) => option.name}
                  value={categories}
                  classes={{
                    option: classes.dropdownOption,
                    tag: classes.tag,
                  }}
                  onChange={(e, newValue) => setCategories(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        disablePublish
                          ? 'Add Minimum 3 Categories'
                          : 'Add Categories'
                      }
                      color="darkGrey"
                      sx={{
                        '& legend': {display: 'none'},
                        '& fieldset': {top: 0},
                        width: '100%',
                        padding: '1rem 0',
                        display: 'flex',
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '1.4rem',
                          color: '#6e6e6e',
                          padding: '0 0rem',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: '1.4rem',
                          fontWeight: 400,
                          padding: '0.2rem 1.4rem',
                        },
                      }}
                    />
                  )}
                  sx={{width: '100%', padding: '0.2rem 1.5rem'}}
                />
                <div className={classes.attachBtnBox}>
                  <div className={classes.attachBtn}>
                    <button onClick={handleAttachFiles}>
                      <ImAttachment/>
                      <div className={classes.attachmentsLength}>
                        {attachments.length}
                      </div>
                    </button>

                  </div>
                </div>
                <div className={classes.nextButtonContainer}>
                  <button
                    className={
                      disablePublish
                        ? `${classes.nextButton} ${classes.btnSaveDisabled}`
                        : `${classes.nextButton} ${classes.btnSave}`
                    }
                    onClick={handlePublish}
                    disabled={disablePublish}
                  >
                    Publish
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        {/*</Grow>*/}
      </Modal>
    </>
  );
};

export default DetailsModal;
